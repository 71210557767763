export interface Banners {
  id: any;
  title: any;
  src: string;
  alt: string;
}
export interface CategoryProducts {
  productId: number;
  productName: string;
  productThumbnail: string;
}

export interface Icon1 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon2 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon3 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon4 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon5 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon6 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon7 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon8 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon9 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon10 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon11 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon12 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}

export interface Icon13 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon14 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon15 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon16 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon17 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon18 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon19 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon20 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon21 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}
export interface Icon22 {
  idTop: any;
  srcTop: string;
  titleTop: any;
  idBottom: any;
  srcBottom: string;
  titleBottom: any;
}

export interface Icon23 {
  idTop: any;
  srcTop: string;
  titleTop: any;
}

export class Homepage {
  banner: Banners[] = [
    {
      id: '1',
      title: 'Banner 1',
      src: 'https://bbifiledata.s3.ap-south-1.amazonaws.com/BBI_Banner_1.png',
      alt: 'Banner Image',
    },
    {
      id: '2',
      title: 'Banner 2',
      src: 'https://bbifiledata.s3.ap-south-1.amazonaws.com/BBI_Banner_2.png',
      alt: 'Banner Image',
    },
    {
      id: '3',
      title: 'Banner 3',
      src: 'https://bbifiledata.s3.ap-south-1.amazonaws.com/BBI_Banner_3.jpeg',
      alt: 'Banner Image',
    },
  ];

  industrialMachinery: CategoryProducts[] = [
    {
      productId: 1715,
      productName: 'Wood-working Machinery',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Wood%20Work%20Machinery_1714998594967.png',
    },
    {
      productId: 1716,
      productName: 'Welding Equipment',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Welding%20Equipment_1714998594877.png',
    },
    {
      productId: 1720,
      productName: 'Cleaning Equipment',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Cleaning%20Equipment_1714998594583.png',
    },
    {
      productId: 1721,
      productName: 'Printing Machine',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Printing%20Machine_1714998594789.png',
    },
    {
      productId: 1722,
      productName: 'Paper Production Machinery',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Paper%20Production%20Machinery_1714998594751.png',
    },
    {
      productId: 1725,
      productName: 'Packaging Machines',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Packaging%20Machinery_1714998594670.png',
    },

    {
      productId: 1727,
      productName: 'Apparel & Textile Machinery',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Apparel%20%26%20Textile%20Machinery_1714998594538.png',
    },

    {
      productId: 1728,
      productName: 'Agricultural Machinery & Equipment',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/agriculture%20machinery%20and%20equipment_1714998594393.png',
    },
  ];

  vehicleAccessories: CategoryProducts[] = [
    {
      productId: 1447,
      productName: 'Fluid & Chemicals',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/fluid%20and%20chemicals_1714999639415.png',
    },
    {
      productId: 1448,
      productName: 'Interior Accessories',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/interior%20accessories_1714999639571.png',
    },
    {
      productId: 1449,
      productName: 'Exterior Accessories',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/exterior%20accessories_1714999639268.png',
    },
    {
      productId: 1450,
      productName: 'Auto Electronics',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/auto%20electronics_1714999638982.png',
    },
    {
      productId: 1451,
      productName: 'Vehicle Tools',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/vehicle%20tools_1714999639881.png',
    },
    {
      productId: 1453,
      productName: 'Recovery & Off-road Accessories',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/offroad%20and%20recovery%20accessories_1714999639663.png',
    },
    {
      productId: 1454,
      productName: 'Petrol Service Equipment',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/petrol%20service%20equipment_1714999639771.png',
    },
    {
      productId: 1455,
      productName: 'Car Care & Cleanings',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Car%20care%20products_1714999639141.png',
    },
  ];

  homeAppliances: CategoryProducts[] = [
    {
      productId: 2390,
      productName: 'Smart Home Appliances',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/smart%20home%20appliances_1714998114235.png',
    },
    {
      productId: 2393,
      productName: 'Cleaning Appliances',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Cleaning%20Appliances_1714998113784.png',
    },
    {
      productId: 2394,
      productName: 'Kitchen Appliances',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Kitchen%20Appliances_1714998113917.png',
    },
    {
      productId: 2396,
      productName: 'Refrigerators & Freezers',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/refrigerators_1714998114137.png',
    },
    {
      productId: 2397,
      productName: 'Air Quality Appliances',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Air%20Purifier_1714998113510.png',
    },
    {
      productId: 2400,
      productName: 'Laundry Appliances',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/auto%20electronics_1715065816277.png',
    },
    {
      productId: 2402,
      productName: 'Water Treatment Appliances',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/water%20treatment%20appliances_1714998114320.png',
    },
    {
      productId: 2404,
      productName: 'Personal Care & Beauty Appliances',
      productThumbnail:
        'https://bbifiledata.s3.ap-south-1.amazonaws.com/Personal%20Care%20%26%20Beauty%20Appliances_1714998114047.png',
    },
  ];

  icon1: Icon1[] = [
    {
      idTop: 187,
      titleTop: 'Construction & Building Machinery',
      srcTop: '/assets/images/Category Icons/Agriculture.svg',
      idBottom: 190,
      titleBottom: 'Apparel & Accessories',
      srcBottom: '/assets/images/Category Icons/Apparel & Accessories.svg',
    },
  ];
  icon2: Icon2[] = [
    {
      idTop: 198,
      titleTop: 'Beauty',
      srcTop: '/assets/images/Category Icons/Beauty.svg',
      idBottom: 222,
      titleBottom: 'Business Services',
      srcBottom: '/assets/images/Category Icons/Business Services.svg',
    },
  ];
  icon3: Icon3[] = [
    {
      idTop: 217,
      titleTop: 'Chemicals',
      srcTop: '/assets/images/Category Icons/Chemicals.svg',
      idBottom: 221,
      titleBottom: 'Commercial Service Equipment',
      srcBottom:
        '/assets/images/Category Icons/Commercial Service Equipment.svg',
    },
  ];
  icon4: Icon4[] = [
    {
      idTop: 187,
      titleTop: 'Construction & Building Machinery',
      srcTop:
        '/assets/images/Category Icons/Construction & Building Machinery.svg',
      idBottom: 193,
      titleBottom: 'Construction & Real Estate',
      srcBottom: '/assets/images/Category Icons/Construction & Real Estate.svg',
    },
  ];
  icon5: Icon5[] = [
    {
      idTop: 188,
      titleTop: 'Consumer Electronics',
      srcTop: '/assets/images/Category Icons/Consumer Electronics.svg',
      idBottom: 205,
      titleBottom: 'Electrical Equipment & Supplies',
      srcBottom:
        '/assets/images/Category Icons/Electrical Equipment & Supplies.svg',
    },
  ];
  icon6: Icon6[] = [
    {
      idTop: 210,
      titleTop: 'Electronic, Accessories & Telecommunications',
      srcTop:
        '/assets/images/Category Icons/Electronic Accessories & Telecommunications.svg',
      idBottom: 224,
      titleBottom: 'Environment',
      srcBottom: '/assets/images/Category Icons/Environment.svg',
    },
  ];
  icon7: Icon7[] = [
    {
      idTop: 196,
      titleTop: 'Fabric & Textile Raw Material',
      srcTop: '/assets/images/Category Icons/Fabric & Textile Raw Material.svg',
      idBottom: 209,
      titleBottom: 'Fabrication Services',
      srcBottom: '/assets/images/Category Icons/Fabrication Services.svg',
    },
  ];
  icon8: Icon8[] = [
    {
      idTop: 220,
      titleTop: 'Food & Beverage',
      srcTop: '/assets/images/Category Icons/Food & Beverage.svg',
      idBottom: 195,
      titleBottom: 'Furniture',
      srcBottom: '/assets/images/Category Icons/Furniture.svg',
    },
  ];
  icon9: Icon9[] = [
    {
      idTop: 213,
      titleTop: 'Gifts & Crafts',
      srcTop: '/assets/images/Category Icons/Gifts & Crafts.svg',
      idBottom: 200,
      titleBottom: 'Health Care',
      srcBottom: '/assets/images/Category Icons/Health Care.svg',
    },
  ];
  icon10: Icon10[] = [
    {
      idTop: 225,
      titleTop: 'Power Transmission',
      srcTop: '/assets/images/Category Icons/Power Transmission.svg',
      idBottom: 206,
      titleBottom: 'Tools & Hardware',
      srcBottom: '/assets/images/Category Icons/Tools & Hardware.svg',
    },
  ];
  icon11: Icon11[] = [
    {
      idTop: 189,
      titleTop: 'Home  Appliances',
      srcTop: '/assets/images/Category Icons/Home  Appliances.svg  ',
      idBottom: 194,
      titleBottom: 'Home & Garden',
      srcBottom: '/assets/images/Category Icons/Home & Garden.svg',
    },
  ];
  icon12: Icon12[] = [
    {
      idTop: 186,
      titleTop: 'Industrial Machinery',
      srcTop: '/assets/images/Category Icons/Industrial Machinery.svg',
      idBottom: 191,
      titleBottom: 'Jewelry Eyewear Watches & Accessories',
      srcBottom:
        '/assets/images/Category Icons/Jewelry Eyewear Watches & Accessories.svg',
    },
  ];
  icon13: Icon13[] = [
    {
      idTop: 229,
      titleTop: 'Laminates',
      srcTop: '/assets/images/Category Icons/Laminates.svg',
      idBottom: 192,
      titleBottom: 'Lights &  Lightings',
      srcBottom: '/assets/images/Category Icons/Lights &  Lightings.svg',
    },
  ];
  icon14: Icon14[] = [
    {
      idTop: 214,
      titleTop: 'Luggage, Bags & Cases',
      srcTop: '/assets/images/Category Icons/Luggage, Bags & Cases.svg',
      idBottom: 226,
      titleBottom: 'Material Handling',
      srcBottom: '/assets/images/Category Icons/Material Handling.svg',
    },
  ];
  icon15: Icon15[] = [
    {
      idTop: 201,
      titleTop: 'Medical Devices & Supplies',
      srcTop: '/assets/images/Category Icons/Medical Devices & Supplies.svg',
      idBottom: 216,
      titleBottom: 'Metals & Alloys',
      srcBottom: '/assets/images/Category Icons/Metals & Alloys.svg',
    },
  ];
  icon16: Icon16[] = [
    {
      idTop: 212,
      titleTop: 'Mother KidTops & Toys',
      srcTop: '/assets/images/Category Icons/Mother Kids & Toys.svg',
      idBottom: 202,
      titleBottom: 'Packaging & Printing',
      srcBottom: '/assets/images/Category Icons/Packaging & Printing.svg',
    },
  ];
  icon17: Icon17[] = [
    {
      idTop: 199,
      titleTop: 'Personal Care & Household Cleaning',
      srcTop:
        '/assets/images/Category Icons/Personal Care & Household Cleaning.svg',
      idBottom: 197,
      titleBottom: 'Pet  Supplies',
      srcBottom: '/assets/images/Category Icons/Pet  Supplies.svg',
    },
  ];
  icon18: Icon18[] = [
    {
      idTop: 223,
      titleTop: 'Renewable Energy',
      srcTop: '/assets/images/Category Icons/Renewable Energy.svg',
      idBottom: 218,
      titleBottom: 'Rubber & Plastics',
      srcBottom: '/assets/images/Category Icons/Rubber & Plastics.svg',
    },
  ];
  icon19: Icon19[] = [
    {
      idTop: 208,
      titleTop: 'Safety',
      srcTop: '/assets/images/Category Icons/Safety.svg',
      idBottom: 203,
      titleBottom: 'School &  Office Supplies',
      srcBottom: '/assets/images/Category Icons/School &  Office Supplies.svg',
    },
  ];
  icon20: Icon20[] = [
    {
      idTop: 207,
      titleTop: 'Security',
      srcTop: '/assets/images/Category Icons/Security.svg',
      idBottom: 215,
      titleBottom: 'Shoes & Accessories',
      srcBottom: '/assets/images/Category Icons/Shoes & Accessories.svg',
    },
  ];
  icon21: Icon21[] = [
    {
      idTop: 211,
      titleTop: 'Sports & Entertainment',
      srcTop: '/assets/images/Category Icons/Sports & Entertainment.svg',
      idBottom: 206,
      titleBottom: 'Tools & Hardware',
      srcBottom: '/assets/images/Category Icons/Tools & Hardware.svg',
    },
  ];
  icon22: Icon22[] = [
    {
      idTop: 185,
      titleTop: 'Vehicle Accessories, Electronics, & Tools',
      srcTop:
        '/assets/images/Category Icons/Vehicle Accessories, Electronics, & Tools.svg',
      idBottom: 227,
      titleBottom: 'Vehicle Parts & Accessories',
      srcBottom:
        '/assets/images/Category Icons/Vehicle Parts & Accessories.svg',
    },
  ];
  icon23: Icon23[] = [
    {
      idTop: 184,
      titleTop: 'Vehicles &  Transportation',
      srcTop: '/assets/images/Category Icons/Vehicles &  Transportation.svg',
    },
  ];
}
