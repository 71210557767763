<section id="carousel-product-card" class="paddingmobile">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ title }}</mat-card-title>
      <mat-card-subtitle><a (click)="viewAll()">View All</a></mat-card-subtitle>
    </mat-card-header>
    <owl-carousel-o [options]="products">
      <ng-container *ngFor="let product of productCard">
        <ng-template carouselSlide>
          <mat-card>
            <div [id]="product.productDetailsId" (click)="viewProduct(product?.productDetailsId)">
              <img [src]="
                  product.productImageUrl
                    ? product.productImageUrl
                    : '/assets/images/placeholder.png'
                " alt="productImage" />
              <span>{{ product.productName }}</span>
            </div>
          </mat-card>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </mat-card>
</section>