<div class="row cards">
  <div class="cards-body" *ngFor="let item of cardItems">
    <div class="row cards-item" [ngClass]="item.class">
      <div class="col-md-10 label">
        <span>{{ item.label }}</span>
        <h5>{{ item.count }}</h5>
      </div>
      <div class="col-md-2 image">
        <img [src]="item.image" alt="icon" />
      </div>
      <div class="col-md-12 days">
        <span>{{ item.days }}</span>
      </div>
    </div>
  </div>
</div>
