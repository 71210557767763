import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-carousel',
  templateUrl: './breadcrumb-carousel.component.html',
  styleUrls: ['./breadcrumb-carousel.component.scss']
})
export class BreadcrumbCarouselComponent implements OnInit {
 // @Input() slides!: string[]; // Array of items to display in the carousel
  responsiveOptions!: any[];
  @Input() heading: string ='Shop By Brand';
  @Input() slides: BrandSlides[] =[];
  constructor() {
    this.responsiveOptions = [
      {
          breakpoint: '1199px',
          numVisible: 5,
          numScroll: 1
      },
      {
          breakpoint: '991px',
          numVisible: 3,
          numScroll: 1
      },
      {
          breakpoint: '767px',
          numVisible: 2,
          numScroll: 1
      }
  ];
  }

  ngOnInit(): void {

  }

}
export interface BrandSlides {
  title?: String;
  image?: String;
}
