<div id="landing-page">
  <app-header></app-header>

  <!-- Hero Section -->

  <section class="row banner marginmobile">
    <div class="col-md-2 categories-list">
      <div class="Categories">
        <img
          routerLink="/products/all-product"
          src="/assets/images/landing-page/dates.png"
          alt=""
        />
        <strong class="cat">Categories</strong>
        <ul>
          <li
            *ngFor="let category of allCategory"
            (click)="viewCategoryProduct(category.categoryId)"
          >
            <div class="item">
              <span>{{ category?.categoryName }}</span>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </li>
        </ul>
        <!-- <span><a routerLink="/products/null">View More Categories</a></span> -->
      </div>
    </div>
    <div class="col-md-8 banner-section">
      <div class="banner-top">
        <owl-carousel-o [options]="banner">
          <ng-container *ngFor="let banner of homePage.banner">
            <ng-template carouselSlide>
              <img
                [src]="banner.src"
                [alt]="banner.alt"
                [title]="banner.title"
              />
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <div class="banner-bottom" routerLink="/auth/sign-up">
        <img src="/assets/images/header/Frame 63117 (1).png" alt="" />
      </div>
    </div>
    <div class="col-md-2 trending-product">
      <strong>Trending Products</strong>
      <div *ngIf="isLoading" class="loading-spinner">
        <mat-spinner diameter="30"></mat-spinner>
      </div>
      <ul>
        <li
          *ngFor="let product of allProducts?.slice(0, 8)"
          (click)="viewProduct(product?.productDetailsId)"
        >
          <img
            [src]="
              product.productImageUrl
                ? product.productImageUrl
                : '/assets/images/placeholder.png'
            "
            alt="alt"
          />
          <span>{{ product.productName | titlecase }}</span>
        </li>
      </ul>
      <p>What You’re Looking For?</p>
      <div class="col-md-12 info-btn-dark quote-btn">
        <button mat-button (click)="openRequestQuote(null, null)">
          Ask For A Quote
        </button>
      </div>
    </div>

    <!-- mobile -->

    <div class="col-md-2 trending-product-mobile">
      <strong>Trending Products</strong>
      <ul>
        <div class="row">
          <div class="col-md-6 w-50">
            <li
              *ngFor="let product of allProducts?.slice(0, 3)"
              (click)="viewProduct(product?.productDetailsId)"
            >
              <img
                [src]="
                  product.productImageUrl
                    ? product.productImageUrl
                    : '/assets/images/placeholder.png'
                "
                alt="alt"
              />
              <span>{{ product.productName | titlecase }}</span>
            </li>
          </div>
          <div class="col-md-6 w-50">
            <li
              *ngFor="let product of allProducts?.slice(3, 6)"
              (click)="viewProduct(product?.productDetailsId)"
            >
              <img
                [src]="
                  product.productImageUrl
                    ? product.productImageUrl
                    : '/assets/images/placeholder.png'
                "
                alt="alt"
              />
              <span>{{ product.productName | titlecase }}</span>
            </li>
          </div>
        </div>
      </ul>
      <!-- <div class="view-more">
          <span><a routerLink="/">View More</a></span>
        </div> -->
      <p>Couldn’t Find What You’re Looking For?</p>
      <div class="col-md-12 info-btn-dark quote-btn">
        <button mat-button (click)="openRequestQuote(null, null)">
          Ask For A Quote
        </button>
      </div>
    </div>
  </section>

  <!-- Category-Products-Row -->
  <section class="row banner-card paddingmobile">
    <div class="row category-products">
      <div class="col-md-3 category-image industrial-machinery">
        <h5>Industrial Machinery</h5>
        <button
          class="btn btn-primary mt-3"
          (click)="viewCategoryProduct(industrialMachineryCategoryId)"
        >
          View Products
        </button>
      </div>
      <div class="col-md-9">
        <div class="row">
          <mat-card
            *ngFor="let categoryProduct of homePage.industrialMachinery"
            class="col-md-3"
            (click)="viewCategoryProduct(categoryProduct.productId)"
          >
            <mat-card-header>
              <mat-card-title>
                {{ categoryProduct.productName }}</mat-card-title
              >
            </mat-card-header>
            <mat-card-content>
              <img
                mat-card-image
                [src]="
                  categoryProduct.productThumbnail
                    ? categoryProduct.productThumbnail
                    : ''
                "
                alt="image"
              />
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div class="row category-products">
      <div class="col-md-3 category-image vehicle-parts">
        <h5>Vehicle Parts & Accessories</h5>
        <button
          class="btn btn-primary mt-3"
          (click)="viewCategoryProduct(vehiclePartCategoryId)"
        >
          View Products
        </button>
      </div>
      <div class="col-md-9">
        <div class="row">
          <mat-card
            *ngFor="let categoryProduct of homePage.vehicleAccessories"
            class="col-md-3"
            (click)="viewCategoryProduct(categoryProduct.productId)"
          >
            <mat-card-header>
              <mat-card-title>
                {{ categoryProduct.productName }}</mat-card-title
              >
            </mat-card-header>
            <mat-card-content>
              <img
                mat-card-image
                [src]="
                  categoryProduct.productThumbnail
                    ? categoryProduct.productThumbnail
                    : ''
                "
                alt="image"
              />
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="row category-products">
      <div class="col-md-3 category-image home-appliances">
        <h5>Home Appliances</h5>
        <button
          class="btn btn-primary mt-3"
          (click)="viewCategoryProduct(homeAppliancesCategoryId)"
        >
          View Products
        </button>
      </div>
      <div class="col-md-9">
        <div class="row">
          <mat-card
            *ngFor="let categoryProduct of homePage.homeAppliances"
            class="col-md-3"
            (click)="viewCategoryProduct(categoryProduct.productId)"
          >
            <mat-card-header>
              <mat-card-title>
                {{ categoryProduct.productName }}</mat-card-title
              >
            </mat-card-header>
            <mat-card-content>
              <img
                mat-card-image
                [src]="
                  categoryProduct.productThumbnail
                    ? categoryProduct.productThumbnail
                    : ''
                "
                alt="image"
              />
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>

    <div id="new-arrivals" class="col-md-4 card-item mt-3">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <span>New Arrivals</span>
            <a (click)="viewCategoryProduct('all-product')">View All</a>
          </mat-card-title>
        </mat-card-header>
        <div *ngIf="isLoading" class="loading-spinner">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
        <div class="row image">
          <div
            class="col-md-6"
            *ngFor="let product of allProducts?.slice(0, 4)"
          >
            <mat-card-content
              (click)="viewProduct(product?.productDetailsId)"
              [matTooltip]="product?.productName | titlecase"
            >
              <img
                mat-card-image
                [src]="
                  product.productImageUrl
                    ? product.productImageUrl
                    : '/assets/images/placeholder.png'
                "
                alt="image"
              />
              <span>{{ product?.productName }} </span>
            </mat-card-content>
          </div>
        </div>
      </mat-card>
    </div>
    <div id="best-seller" class="col-md-4 card-item mt-3">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Best Sellers</mat-card-title>
        </mat-card-header>
        <div *ngIf="isLoading" class="loading-spinner">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
        <div class="row image">
          <div
            class="col-md-6"
            *ngFor="let product of allProducts?.slice(4, 8)"
          >
            <mat-card-content
              (click)="viewProduct(product?.productDetailsId)"
              [matTooltip]="product?.productName | titlecase"
            >
              <img
                mat-card-image
                [src]="
                  product.productImageUrl
                    ? product.productImageUrl
                    : '/assets/images/placeholder.png'
                "
                alt="image"
              />
              <span>{{ product?.productName }} </span>
            </mat-card-content>
          </div>
        </div>
      </mat-card>
    </div>
    <div id="selected-suppliers" class="col-md-4 card-item mt-3">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Selected Suppliers</mat-card-title>
        </mat-card-header>
        <div class="row image">
          <div
            class="col-md-6"
            *ngFor="let company of companyList?.slice(0, 4)"
          >
            <mat-card-content [matTooltip]="company.companyName | uppercase">
              <img
                mat-card-image
                [src]="
                  company.companyLogo
                    ? company.companyLogo
                    : '/assets/images/company-logo-placeholder.png'
                "
                alt="logo"
              />
              <span>{{ company?.companyName }} </span>
            </mat-card-content>
          </div>
        </div>
      </mat-card>
    </div>
  </section>

  <!-- icon-listing  -->
  <section id="icon-listing">
    <div class="col-md-12 text-top">
      <h5>03 Simple Step Process To Get A Quote</h5>
    </div>
    <div class="row step-process">
      <div class="col-md-4 search">
        <span
          ><img src="/assets/images/landing-page/icon-search.png" alt=""
        /></span>
        <span>Search for Products</span>
        <p>
          Search for products in the search bar or browse through the
          categories.
        </p>
      </div>
      <div class="col-md-4 quote">
        <span
          ><img
            class="green"
            src="/assets/images/landing-page/Scroll.png"
            alt=""
        /></span>
        <span>Get Quotes from Sellers</span>
        <p>
          Fill the form and click "Get Quotes" button & start receiving
          quotations.
        </p>
      </div>
      <div class="col-md-4 cart">
        <span
          ><img
            class="pink"
            src="/assets/images/landing-page/ShoppingCart.png"
            alt=""
        /></span>
        <span>Choose the Best Seller</span>
        <p>Choose the best seller by comparing prices. Then, place an order.</p>
      </div>
    </div>
    <div id="categories" class="col-md-12 icon-item">
      <h5>From<strong> A to Z,</strong> we've got you covered.</h5>
      <span>Choose from <strong class="orange"> 45+ Categories </strong></span>
      <owl-carousel-o [options]="iconListing">
        <ng-container *ngFor="let icon of homePage.icon1">
          <ng-template carouselSlide>
            <div (click)="viewCategoryProduct(icon.idTop)" class="icon-item">
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon2">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon3">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon4">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon5">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon6">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon7">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon8">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon9">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon10">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon11">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon12">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon13">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon14">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon15">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon16">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon17">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon18">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon19">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon20">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon21">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon22">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
            <div
              class="icon-item mt-2"
              (click)="viewCategoryProduct(icon.idBottom)"
            >
              <span><img [src]="icon.srcBottom" [alt]="" /></span>
              <span class="line-break">{{ icon.titleBottom }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngFor="let icon of homePage.icon2">
          <ng-template carouselSlide>
            <div class="icon-item" (click)="viewCategoryProduct(icon.idTop)">
              <span><img [src]="icon.srcTop" [alt]="" /></span>
              <span class="line-break">{{ icon.titleTop }}</span>
            </div>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </section>

  <!--most loved products -->
  <div id="most-loved-product" class="paddingmobile">
    <app-carousel-product-card
      [productCard]="allProducts"
      title="Customers’ Most-Loved Products"
      (onClick)="viewProduct($event)"
      (onClickView)="viewCategoryProduct('all-product')"
    >
    </app-carousel-product-card>
  </div>

  <!-- support -->
  <app-support></app-support>

  <!-- footer -->
  <app-footer></app-footer>
</div>
