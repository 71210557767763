import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private cookieService: CookieService) {}

  accessToken: any;
  httpOptions: any;

  getToken() {
    const token = localStorage.getItem('accessToken');
    if (token !== null) {
      this.accessToken = JSON.parse(token);
    } else {
      console.error('Access token is null');
    }

    this.httpOptions = {
      headers: new HttpHeaders({
        accessToken: this.accessToken,
      }),
    };

  }
  isLoggedIn(): Boolean {
    const currentUser = localStorage.getItem('currentUser');
    if (currentUser != null && currentUser != 'undefined') {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('userRoleId');
    this.cookieService.delete('auth_token');
    // sessionStorage.clear();
    this.router.navigateByUrl('/');
    return true;
  }

  allowedNumbersOnly(event: any) {
    let key = event.key;
    let acceptableKey = [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '0',
      '+',
      'Control',
      'Backspace',
      'Alt',
      'Shift',
      'Tab',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown',
    ];
    //let acceptAfter10 = ['Control', 'Backspace', 'Alt', 'Shift', "ArrowLeft", "ArrowRight", 'ArrowUp', 'ArrowDown'];
    if (!acceptableKey.includes(key)) {
      event.preventDefault();
    }
    // else {
    //   if(!acceptAfter10.includes(key)){
    //       event.preventDefault();
    //   }
    // }
  }
}
