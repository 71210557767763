// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  recaptcha: {
    siteKey: '6LcvooclAAAAADLFyRzOmLCB-GqFtO8F05-GZYQ5',
  },
  mailChimp: {
    apiKey: 'ae4d270223c4113a61c8a42fc08877a8-us21',
    listId: '70860d4bca',
    url: 'https://us21.api.mailchimp.com/3.0/lists/70860d4bca/members',
  },
  emailJs: {
    publicKey: 'mLfa8GL3I83NVAuHu',
    serviceId: 'service_nxw38ye',
    templateId: 'template_gpq6l6k',
  },
  brevo: {
    apiKey:
      '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',
  },
  hspSMS: {
    apiKey: '804a6534-3de4-4ae7-b6da-201e0021927b',
    apiUrl: 'http://sms.hspsms.com',
    username: '9818811050',
    sendername: 'Skydec',
  },
  GSTIN: {
    apiKey: '9248c2f2-1506-4763-b134-b09585132c65',
    apiUrl: 'https://taxpayer.irisgst.com',
  },

  uploadURL: {
    userAPIUrl:
      'https://8ite7mlubc.execute-api.ap-south-1.amazonaws.com/bbiimage',
    productAPIUrl:
      'https://8ite7mlubc.execute-api.ap-south-1.amazonaws.com/bbiimage',
    bulkUploadSampleUrl:
      'https://bbifiledata.s3.ap-south-1.amazonaws.com/bulk-upload-sample_1712153194163.xlsx',
  },

  baseApiUrl: 'https://wmrx1a8l2d.execute-api.ap-south-1.amazonaws.com/bulkbuy', //dev-url
  // baseApiUrl: 'https://2nxj6gsn28.execute-api.ap-south-1.amazonaws.com/bulkbuy', //prod-url
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
