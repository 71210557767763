<mat-card id="product-card">
  <span
    class="product-image"
    (click)="viewProduct(productCard.productDetailsId)"
  >
    <img
      mat-card-image
      [src]="
        productCard.productImageUrl
          ? productCard.productImageUrl
          : '/assets/images/placeholder.png'
      "
      alt="productImage"
    />
  </span>
  <mat-card-content>
    <div class="product-price">
      <span class="price">
        <!-- <img src="/assets/images/inr-symbol.png" /> -->
        <span class="currency-code">{{ currencyCode }} </span>
        {{
          productCard.salePrice
            ? ((productCard.salePrice / dollarRate) * exchangeRate
              | number : "1.2-2")
            : ((productCard.productPrice / dollarRate) * exchangeRate
              | number : "1.2-2")
        }}
      </span>
      <div class="discounted-price">
        <del *ngIf="productCard.salePrice"
          >(<span class="currency-code">{{ currencyCode }} </span>
          {{
            (productCard.productPrice / dollarRate) * exchangeRate
              | number : "1.2-2"
          }}
          )</del
        ><span class="d-none">(off %)</span>
      </div>
    </div>
    <span class="title">{{ productCard.productName }} </span>
    <div class="hsn">
      <mat-label class="title">HSN Code: </mat-label>
      <span>{{ productCard.hsnCode ? productCard.hsnCode : "NA" }} </span>
    </div>
    <div class="brand">
      <img
        [src]="
          productCard.companyResponseDTO?.companyLogo
            ? productCard.companyResponseDTO?.companyLogo
            : '/assets/images/company-logo-placeholder.png'
        "
        alt="logo"
      />
      <span class="company-name">
        {{ productCard.companyResponseDTO?.companyName | titlecase }}
      </span>
      <!-- <span class="location">{{ productCard.location }} </span> -->
    </div>
    <mat-card-actions>
      <div class="row">
        <div
          class="col-md-6 quote"
          (click)="
            openRequestQuote(productCard.productName, productCard.companyId)
          "
        >
          <button mat-button>Request Quote</button>
        </div>
        <div
          class="col-md-6 view-product"
          [id]="productCard.productDetailsId"
          (click)="viewProduct(productCard.productDetailsId)"
        >
          <button mat-button>View Product</button>
        </div>
      </div>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
