export interface CurrencyInterface {
    value: string;
    viewValue: string;
  }
  
  export class Currency{

  currencies: CurrencyInterface[] = [
    { value: 'INR', viewValue: 'INR' },
    { value: 'ZMK', viewValue: 'ZMK' },
    { value: 'YER', viewValue: 'YER' },
    { value: 'VND', viewValue: 'VND' },
    { value: 'VEF', viewValue: 'VEF' },
    { value: 'VUV', viewValue: 'VUV' },
    { value: 'UZS', viewValue: 'UZS' },
    { value: 'USD', viewValue: 'US' },
    { value: 'UYU', viewValue: 'UYU' },
    { value: 'AED', viewValue: 'AED' },
    { value: 'UAH', viewValue: 'UAH' },
    { value: 'UGX', viewValue: 'UGX' },
    { value: 'TMT', viewValue: 'TMT' },
    { value: 'TRY', viewValue: 'TRY' },
    { value: 'TND', viewValue: 'TND' },
    { value: 'TTD', viewValue: 'TTD' },
    { value: 'TOP', viewValue: 'TOP' },
    { value: 'THB', viewValue: 'THB' },
    { value: 'TZS', viewValue: 'TZS' },
    { value: 'TJS', viewValue: 'TJS' },
    { value: 'STD', viewValue: 'STD' },
    { value: 'SYP', viewValue: 'SYP' },
    { value: 'CHF', viewValue: 'CHF' },
    { value: 'SEK', viewValue: 'SEK' },
    { value: 'SZL', viewValue: 'SZL' },
    { value: 'SRD', viewValue: 'SRD' },
    { value: 'SDG', viewValue: 'SDG' },
    { value: 'SHP', viewValue: 'SHP' },
    { value: 'LKR', viewValue: 'LKR' },
    { value: 'XDR', viewValue: 'XDR' },
    { value: 'KRW', viewValue: 'KRW' },
    { value: 'ZAR', viewValue: 'ZAR' },
    { value: 'SOS', viewValue: 'SOS' },
    { value: 'SBD', viewValue: 'SBD' },
    { value: 'SKK', viewValue: 'SKK' },
    { value: 'SGD', viewValue: 'SGD' },
    { value: 'SLL', viewValue: 'SLL' },
    { value: 'SCR', viewValue: 'SCR' },
    { value: 'RSD', viewValue: 'RSD' },
    { value: 'SAR', viewValue: 'SAR' },
    { value: 'WST', viewValue: 'WST' },
    { value: 'SVC', viewValue: 'SVC' },
    { value: 'RWF', viewValue: 'RWF' },
    { value: 'RUB', viewValue: 'RUB' },
    { value: 'RON', viewValue: 'RON' },
    { value: 'QAR', viewValue: 'QAR' },
    { value: 'PLN', viewValue: 'PLN' },
    { value: 'PEN', viewValue: 'PEN' },
    { value: 'PYG', viewValue: 'PYG' },
    { value: 'PGK', viewValue: 'PGK' },
    { value: 'PAB', viewValue: 'PAB' },
    { value: 'PKR', viewValue: 'PKR' },
    { value: 'OMR', viewValue: 'OMR' },
    { value: 'NOK', viewValue: 'NOK' },
    { value: 'KPW', viewValue: 'KPW' },
    { value: 'NGN', viewValue: 'NGN' },
    { value: 'NIO', viewValue: 'NIO' },
    { value: 'NZD', viewValue: 'NZD' },
    { value: 'TWD', viewValue: 'TWD' },
    { value: 'ANG', viewValue: 'ANG' },
    { value: 'NPR', viewValue: 'NPR' },
    { value: 'NAD', viewValue: 'NAD' },
    { value: 'MMK', viewValue: 'MMK' },
    { value: 'MZM', viewValue: 'MZM' },
    { value: 'MAD', viewValue: 'MAD' },
    { value: 'MNT', viewValue: 'MNT' },
    { value: 'MDL', viewValue: 'MDL' },
    { value: 'MXN', viewValue: 'MXN' },
    { value: 'MUR', viewValue: 'MUR' },
    { value: 'MRO', viewValue: 'MRO' },
    { value: 'MVR', viewValue: 'MVR' },
    { value: 'MYR', viewValue: 'MYR' },
    { value: 'MWK', viewValue: 'MWK' },
    { value: 'MGA', viewValue: 'MGA' },
    { value: 'MKD', viewValue: 'MKD' },
    { value: 'MOP', viewValue: 'MOP' },
    { value: 'LTL', viewValue: 'LTL' },
    { value: 'LRD', viewValue: 'LRD' },
    { value: 'LSL', viewValue: 'LSL' },
    { value: 'LBP', viewValue: 'LBP' },
    { value: 'LVL', viewValue: 'LVL' },
    { value: 'LAK', viewValue: 'LAK' },
    { value: 'KWD', viewValue: 'KWD' },
    { value: 'KES', viewValue: 'KES' },
    { value: 'KZT', viewValue: 'KZT' },
    { value: 'JOD', viewValue: 'JOD' },
    { value: 'JPY', viewValue: 'JPY' },
    { value: 'JMD', viewValue: 'JMD' },
    { value: 'ILS', viewValue: 'ILS' },
    { value: 'IQD', viewValue: 'IQD' },
    { value: 'IRR', viewValue: 'IRR' },
    { value: 'IDR', viewValue: 'IDR' },
    { value: 'ISK', viewValue: 'ISK' },
    { value: 'HUF', viewValue: 'HUF' },
  ];

  }
  
