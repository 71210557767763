import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  token: any;
  accessToken: any;
  excludedUrls: string[] = [
    '/productDetails/upload/image',
    '/company/uploadFile',
  ];

  constructor(private router: Router, private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this.authService.isLoggedIn()) {
      this.token = localStorage.getItem('accessToken');
    }
    if (this.token && this.token != 'undefined') {
      this.accessToken = JSON.parse(this.token);
    } else {
      this.accessToken = '';
      // this.router.navigateByUrl('/auth/sign-in');
    }

    // const username = 'amit-bbi';
    // const password = 'amit@134';

    // const modifiedRequest = request.clone({
    //   setHeaders: {
    //     Authorization: `Bearer ${this.accessToken}`,
    //   },
    //   // headers: request.headers.append(
    //   //   'Authorization',
    //   //   `Bearer ${this.accessToken}`
    //   // ),
    //   //body: { ...(request.body as Record<string, any>), username, password },
    //   url: request.url,
    // });

    // Check if the request URL matches any excluded endpoint
    const shouldExclude = this.excludedUrls.some((url) =>
      request.url.includes(url)
    );

    let modifiedRequest = request;

    if (!shouldExclude) {
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.accessToken}`,
        },
      });
    }

    //return next.handle(request);
    return next.handle(modifiedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error && error.error.message == 'Invalid access token!') {
          this.authService.logout();
        }
        return throwError(() => error);
      })
    );
  }
}
