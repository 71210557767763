import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-top-bar-action',
  templateUrl: './top-bar-action.component.html',
  styleUrls: ['./top-bar-action.component.scss']
})
export class TopBarActionComponent implements OnInit {
  @Input() pageTitle: string = 'Role List';
  @Output() onSearchItem = new EventEmitter<any>();
  @Output() bulkStatusChange = new EventEmitter<any>();
  @Output() onAddItem = new EventEmitter<any>();
  @Input() isEnable: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }
  onSearch(event: any) {
    this.onSearchItem.emit(event);
  }
  onBulkStatusChange(event: any){
    this.bulkStatusChange.emit(event);
  }
  onAdd(){
    this.onAddItem.emit();
  }

}
