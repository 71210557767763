export interface RelatedSearchesChips {
  keyword: string;
}

export class ViewProduct {
  relatedSearchesChips: RelatedSearchesChips[] = [
    { keyword: 'Artificial Grass' },
    { keyword: 'Flute Collection' },
    { keyword: '3D Laminates' },
    { keyword: 'Marble Designs' },
    { keyword: 'Modern Abstract' },
    { keyword: 'Mettalic Textures' },
    { keyword: 'Natural Wood' },
    { keyword: 'PVC Laminates' },
    { keyword: 'Solid High Gloss' },
    { keyword: 'Solid Collection' },
    { keyword: 'Solid Sf' },
    { keyword: 'Solid Sparkles' },
    { keyword: 'Sparkle Collection' },
    { keyword: 'Soft Matt Collection' },
    { keyword: 'Wooden Collection' },
    { keyword: 'Wood High Gloss' },
    { keyword: 'Wood Grain' },
  ];
}
