import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PostRequestComponent } from 'src/app/shared/post-request/post-request.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, filter } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ViewProduct } from './view-product';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss'],
})
export class ViewProductComponent implements OnInit, OnDestroy {
  ViewProduct: ViewProduct;
  viewProductDetails: any;
  productId: any;
  galleryImageUrls: any;
  companyId: any;
  categoryId: any;
  primaryAddress: any;
  similarProducts: any;
  currencyCode: string = 'USD';
  dollarRate: number = 1.0;
  exchangeRate: number = 1.0;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private sharedService: SharedService,
    private toastr: ToastrService
  ) {
    this.ViewProduct = new ViewProduct();
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.getUrlString();
      });
    this.getUrlString();
  }
  ngAfterContentChecked(): void {
    this.currencyCode = this.sharedService.currencyCode;
    this.dollarRate = this.sharedService.dollarRate;
    this.exchangeRate = this.sharedService.exchangeRate;
  }
  ngOnDestroy() {
    // Unsubscribe from all subscriptions to avoid memory leaks
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getUrlString() {
    this.route.params.subscribe((params) => {
      this.productId = params['id'];
    });
    this.getProductDetailsById(this.productId);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  getProductDetailsById(productId: any) {
    this.subscriptions.push(
      this.apiService
        .get(`/productDetails/getByProductDetailsId/${productId}`)
        .subscribe({
          next: (res: any) => {
            this.viewProductDetails = res;
            let imageUrl = res?.imageUrls[0];
            if (imageUrl) {
              this.viewProductDetails.imageUrls = imageUrl.split(',');
            }
            this.galleryImageUrls = this.viewProductDetails.imageUrls;
            this.companyId = res.companyId;
            this.categoryId = res.categoryId;
          },
          error: (err: any) => {
            console.error(err.error.message);
          },
          complete: () => {
            this.getAddressByCompanyId();
            this.getproductDetailsByCategoryId();
          },
        })
    );
  }

  getAddressByCompanyId() {
    this.subscriptions.push(
      this.apiService.get(`/address/getAddress/${this.companyId}`).subscribe({
        next: (res: any) => {
          let address = res;
          this.primaryAddress = address.filter(
            (address: any) => address.primaryAddress == true
          );
        },
        error: (err: any) => {
          console.error(err.error.message);
        },
      })
    );
  }

  getproductDetailsByCategoryId() {
    this.subscriptions.push(
      this.apiService
        .get(`/productDetails/ByCategoryId/${this.categoryId}`)
        .subscribe({
          next: (res: any) => {
            let similarProducts = res;
            this.similarProducts = similarProducts.filter((product: any) => {
              return (
                product?.activeInactive == true &&
                product?.productDetailsId.toString() !== this.productId
              );
            });
          },
          error: (err: any) => {
            console.error(err.error.message);
          },
        })
    );
  }

  onKeywordClick(keyword: any) {
    this.router.navigateByUrl(`/products/${keyword}`);
  }

  viewProduct(productId: any) {
    this.router.navigateByUrl(`/product/${productId}`);
  }

  viewGalleryImage(image: any) {
    this.viewProductDetails.productImageUrl = image;
  }

  openRequestQuote(event: any) {
    const sellerCompanyId = event.id;
    const productName = event.name;

    if (this.authService.isLoggedIn()) {
      const dialogRef = this.dialog.open(PostRequestComponent, {
        data: { sellerCompanyId, productName },
      });
      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      this.toastr.warning('Please sign in first to request your quote!');
      this.router.navigateByUrl('auth/sign-in');
    }
  }

  openRequestQuoteDialog(productName: string, sellerCompanyId: number) {
    if (this.authService.isLoggedIn()) {
      const dialogRef = this.dialog.open(PostRequestComponent, {
        data: { sellerCompanyId, productName },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      this.toastr.warning('Please sign in first to request your quote!');
      this.router.navigateByUrl('auth/sign-in');
    }
  }

  similarProductView: OwlOptions = {
    autoplay: true,
    loop: true,
    margin: 10,
    dots: false,
    navSpeed: 500,
    navText: ['&#8249', '&#8250;'],
    autoHeight: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };

  viewedProduct: OwlOptions = {
    autoplay: true,
    loop: true,
    margin: 10,
    dots: false,
    navSpeed: 500,
    navText: ['&#8249', '&#8250;'],
    autoHeight: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };
}
