<nav id="breadcrumb" aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a [routerLink]="homeUrl">
        <fa-icon [icon]="['fas', 'home']"></fa-icon>
      </a>
    </li>
    <li class="breadcrumb-item active" aria-current="page">{{currentPage}}</li>
  </ol>
</nav>
