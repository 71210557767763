<app-header></app-header>
<section id="view-product">
  <app-breadcrumb [homeUrl]="'/'" [currentPage]="'Product'"> </app-breadcrumb>
  <div class="row product-details-top">
    <div class="col-md-4 product-image">
      <img
        [src]="
          viewProductDetails?.productImageUrl
            ? viewProductDetails?.productImageUrl
            : '/assets/images/placeholder.png'
        "
        alt="featured-img"
      />
      <ul class="gallery-images">
        <li *ngFor="let imageUrl of galleryImageUrls">
          <img
            [src]="imageUrl ? imageUrl : '/assets/images/placeholder.png'"
            alt="img"
            (click)="viewGalleryImage(imageUrl)"
          />
        </li>
      </ul>
    </div>
    <div class="col-md-8 product-desc">
      <div class="header row">
        <div class="col-md-6">
          <h5>{{ viewProductDetails?.productName | titlecase }}</h5>
        </div>
        <div class="col-md-6 action">
          <!-- <img src="/assets/images/landing-page/ShareNetwork.png" alt="" /> -->
        </div>
        <span
          ><strong>
            <span class="currency-code">{{ currencyCode }}</span>
            <!-- <img src="/assets/images/inr-symbol.png" /> -->
            {{
              (viewProductDetails?.productPrice / dollarRate) * exchangeRate
                | number : "1.2-2"
            }}</strong
          >
          / {{ viewProductDetails?.priceUnit }}</span
        >
        <p>
          {{ viewProductDetails?.productDescription }}
        </p>
      </div>
      <div class="specification">
        <div class="col-md-3 attrib">
          <mat-label>Color</mat-label>
          <span>{{
            viewProductDetails?.productColour
              ? viewProductDetails?.productColour
              : "NA"
          }}</span>
        </div>

        <div class="col-md-3 attrib">
          <mat-label>Product Size</mat-label>
          <span>{{
            viewProductDetails?.productSize
              ? viewProductDetails?.productSize
              : "NA"
          }}</span>
        </div>

        <div class="col-md-3 attrib">
          <mat-label>Product Material</mat-label>
          <span>{{
            viewProductDetails?.productMaterial
              ? viewProductDetails?.productMaterial
              : "NA"
          }}</span>
        </div>

        <button
          mat-button
          (click)="
            openRequestQuoteDialog(
              viewProductDetails?.productName,
              viewProductDetails?.companyId
            )
          "
        >
          Send Enquiry To Seller
        </button>
      </div>
      <div
        class="row seller-details"
        *ngIf="primaryAddress?.length && primaryAddress !== undefined"
      >
        <div class="col-md-12 logo">
          <img [src]="primaryAddress[0]?.company?.companyLogo" alt="" />
          <h6>{{ primaryAddress[0]?.company?.companyName | uppercase }}</h6>
        </div>
        <div class="col-md-6">
          <!-- <h6>Contact Info:</h6> -->
          <div>
            <mat-label>
              <mat-icon>call</mat-icon>
              {{ primaryAddress[0]?.company?.companyPhone }}</mat-label
            >
          </div>
        </div>
        <div class="col-md-6 email">
          <mat-label
            ><mat-icon>mail_outline</mat-icon>
            {{ primaryAddress[0]?.company?.companyEmail }}</mat-label
          >
        </div>
        <div class="col-md-12 office">
          <!-- <h6>Branch office:</h6> -->
          <mat-label *ngIf="primaryAddress"
            ><mat-icon> location_on</mat-icon>
            {{ primaryAddress[0].address }}
            {{ primaryAddress[0].location }},
            {{ primaryAddress[0].city?.cityName }},
            {{ primaryAddress[0].state?.stateName }} -
            {{ primaryAddress[0].pincode }},
            {{ primaryAddress[0].country?.countryName }}
          </mat-label>
        </div>
      </div>
    </div>
  </div>
  <div class="additional-product-details">
    <h6>Additional Product Details</h6>

    <div class="row specification">
      <div class="col-md-3 attrib">
        <mat-label>Product Length</mat-label>
        <span>{{
          viewProductDetails?.productLength
            ? viewProductDetails?.productLength
            : "NA"
        }}</span>
      </div>

      <div class="col-md-3 attrib">
        <mat-label>Product Width</mat-label>
        <span>{{
          viewProductDetails?.productWidth
            ? viewProductDetails?.productWidth
            : "NA"
        }}</span>
      </div>

      <div class="col-md-3 attrib">
        <mat-label>Product Height</mat-label>
        <span>{{
          viewProductDetails?.productHeight
            ? viewProductDetails?.productHeight
            : "NA"
        }}</span>
      </div>

      <div class="col-md-3 attrib">
        <mat-label>Product Thickness</mat-label>
        <span>{{
          viewProductDetails?.productThickness
            ? viewProductDetails?.productThickness
            : "NA"
        }}</span>
      </div>
      <div class="col-md-3 attrib">
        <mat-label>Product Type</mat-label>
        <span>{{
          viewProductDetails?.productType
            ? viewProductDetails?.productType
            : "NA"
        }}</span>
      </div>

      <div class="col-md-3 attrib">
        <mat-label>Product Code</mat-label>
        <span>{{
          viewProductDetails?.productCode
            ? viewProductDetails?.productCode
            : "NA"
        }}</span>
      </div>

      <div class="col-md-3 attrib">
        <mat-label>Product SKU</mat-label>
        <span>{{
          viewProductDetails?.productSkuid
            ? viewProductDetails?.productSkuid
            : "NA"
        }}</span>
      </div>

      <div class="col-md-3 attrib">
        <mat-label>Product Weight</mat-label>
        <span>{{
          viewProductDetails?.productWeight
            ? viewProductDetails?.productWeight
            : "NA"
        }}</span>
      </div>
    </div>

    <div class="related-searches">
      <h6>Hot/Related Searches</h6>
      <div class="chips-container">
        <mat-chip-list
          class="related-search-list"
          cdkDropList
          cdkDropListOrientation="horizontal"
        >
          <mat-chip
            class="related-search-chip"
            cdkDrag
            *ngFor="let relatedSearch of ViewProduct.relatedSearchesChips"
            (click)="onKeywordClick(relatedSearch.keyword)"
          >
            {{ relatedSearch.keyword }}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
  <div id="similar-product-view" *ngIf="similarProducts?.length > 0">
    <mat-card-title>Similar Products</mat-card-title>
    <owl-carousel-o [options]="similarProductView">
      <ng-container *ngFor="let similarProduct of similarProducts">
        <ng-template carouselSlide>
          <app-product-card
            [productCard]="similarProduct"
            (onClick)="viewProduct($event)"
            (onClickRequest)="openRequestQuote($event)"
          ></app-product-card>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</section>
<app-support></app-support>
<app-footer></app-footer>
