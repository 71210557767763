import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() title?: string = 'loved product';
  @Input() viewAll?: string = 'view all';
  @Input() routerLink?: string = '';
  @Input() productCard: any;
  @Output() onClick = new EventEmitter<any>();
  @Output() onClickRequest = new EventEmitter<{ id: number; name: string }>();
  dollarRate: number = 1;
  exchangeRate: number = 1;
  currencyCode: string = 'USD';
  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    this.currencyCode = this.sharedService.currencyCode;
    this.dollarRate = this.sharedService.dollarRate;
    this.exchangeRate = this.sharedService.exchangeRate;
  }
  viewProduct(id?: any) {
    this.onClick.emit(id);
  }
  openRequestQuote(name?: any, id?: any) {
    this.onClickRequest.emit({ id, name });
  }

  products: OwlOptions = {
    autoplay: false,
    loop: true,
    margin: 10,
    dots: false,
    navSpeed: 500,
    navText: ['&#8249', '&#8250;'],
    autoHeight: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 6,
      },
    },
    nav: true,
  };
}
