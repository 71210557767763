import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  currencyCode: string = 'USD';
  dollarRate: number = 1;
  exchangeRate: number = 1;
  private sharedData: any;

  constructor() {}

  setSharedData(data: any): void {
    this.sharedData = data;
  }

  getSharedData(): any {
    return this.sharedData;
  }

  transformDateTimeIST(transactionTime: any): string {
    const transactionDateUTC = new Date(transactionTime * 1000); // Multiply by 1000 to convert milliseconds to seconds
    const transactionDateIST = transactionDateUTC.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
    });

    return transactionDateIST;
  }
}
