import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseApiUrl = environment.baseApiUrl;
  hspSMSApiUrl = environment.hspSMS.apiUrl;
  gstApiUrl = environment.GSTIN.apiUrl;
  gstApiKey = environment.GSTIN.apiKey;
  userAPIUrl = environment.uploadURL.userAPIUrl;
  productAPIUrl = environment.uploadURL.productAPIUrl;
  accessToken: any;
  httpOptions: any;
  gstHttpOptions = {
    headers: new HttpHeaders({
      apikey: this.gstApiKey,
    }),
  };

  constructor(private httpClient: HttpClient) {}

  getToken() {
    const token = localStorage.getItem('accessToken');
    if (token !== null) {
      this.accessToken = JSON.parse(token);
    } else {
      console.error('Access token is null');
    }

    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.accessToken}`,
      }),
    };
  }

  get(url: any) {
    return this.httpClient.get(`${this.baseApiUrl}${url}`);
  }

  sendSMS(url: any, params: any) {
    return this.httpClient.get(`${this.baseApiUrl}${url}`, { params });
  }

  getGstByNo(url: any) {
    return this.httpClient.get(`${this.gstApiUrl}${url}`, this.gstHttpOptions);
  }

  getById(url: any, id: any) {
    return this.httpClient.get(`${this.baseApiUrl}${url}/${id}`);
  }
  post(url: any, data: any) {
    return this.httpClient.post(`${this.baseApiUrl}${url}`, data);
  }
  uploadUserFile(url: any, data: any) {
    return this.httpClient.post(`${this.userAPIUrl}${url}`, data);
  }
  uploadProductFile(url: any, data: any) {
    return this.httpClient.post(`${this.productAPIUrl}${url}`, data);
  }

  put(url: any, data: any) {
    return this.httpClient.put(`${this.baseApiUrl}${url}`, data);
  }
  patch(url: any, data: any) {
    return this.httpClient.patch(`${this.baseApiUrl}${url}`, data);
  }

  delete(url: any, id: any) {
    return this.httpClient.delete(`${this.baseApiUrl}${url}/${id}`);
  }
  deleteMultiple(url: any, productDetailsIds: number[]) {
    const ids = productDetailsIds.join(',');
    return this.httpClient.delete(
      `${this.baseApiUrl}${url}?productDetailsIds=${ids}`
    );
  }
}
