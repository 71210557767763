import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MaterialModule } from '../material/material.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { CustomButtonComponent } from './reusable/custom-button/custom-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbCarouselComponent } from './reusable/breadcrumb-carousel/breadcrumb-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CustomCardsComponent } from './reusable/custom-cards/custom-cards.component';
import { BreadcrumbComponent } from './reusable/breadcrumb/breadcrumb.component';
import { TopBarActionComponent } from './reusable/top-bar-action/top-bar-action.component';
import { InputSearchComponent } from './reusable/input-search/input-search.component';
import { PostRequestComponent } from './post-request/post-request.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashCardsComponent } from './reusable/dash-cards/dash-cards.component';
import { BbiReportTopbarTagComponent } from './reusable/bbi-report-topbar-tag/bbi-report-topbar-tag.component';
import { SupportComponent } from './support/support.component';
import { EmailUsComponent } from './email-us/email-us.component';
import { SendEmailQuoteComponent } from './reusable/send-email-quote/send-email-quote.component';
import { SafePipeModule } from 'safe-pipe';
import { MatChipsModule } from '@angular/material/chips';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { ProductCardComponent } from './reusable/product-card/product-card.component';
import { CarouselProductCardComponent } from './reusable/carousel-product-card/carousel-product-card.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    CustomButtonComponent,
    BreadcrumbCarouselComponent,
    CustomCardsComponent,
    BreadcrumbComponent,
    TopBarActionComponent,
    InputSearchComponent,
    PostRequestComponent,
    DashCardsComponent,
    BbiReportTopbarTagComponent,
    SupportComponent,
    EmailUsComponent,
    SendEmailQuoteComponent,
    ProductCardComponent,
    CarouselProductCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    RouterModule,
    FontAwesomeModule,
    CarouselModule,
    NgbModule,
    SafePipeModule,
    MatChipsModule,
    SidebarModule,
    ButtonModule,
    NgxPaginationModule,
    NgxMatSelectSearchModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CustomButtonComponent,
    BreadcrumbComponent,
    BreadcrumbCarouselComponent,
    CustomCardsComponent,
    TopBarActionComponent,
    InputSearchComponent,
    DashCardsComponent,
    BbiReportTopbarTagComponent,
    EmailUsComponent,
    SupportComponent,
    SendEmailQuoteComponent,
    ProductCardComponent,
    CarouselProductCardComponent,
  ],
})
export class SharedModule {}
