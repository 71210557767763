import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faBuilding,
  faCircleCheck,
  faCircleLeft,
  faCircleXmark,
  faEdit,
  faEnvelope,
  faHeart,
  faImage,
  faMessage,
  faPenToSquare,
  faSave,
  faTrashCan,
  faXmarkCircle,
  
} from '@fortawesome/free-regular-svg-icons';
import {
  faBuysellads,
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import {
  faAngleDown,
  faAngleRight,
  faArrowRightFromBracket,
  faCirclePlus,
  faCloudArrowUp,
  faDollar,
  faFile,
  faGlobe,
  faHome,
  faLocation,
  faLocationDot,
  faLocationPin,
  faLock,
  faMapLocation,
  faPen,
  faPhone,
  faPhoneAlt,
  faPlus,
  faSearch,
  faTruck,
  faUser,
  faUserPen,
} from '@fortawesome/free-solid-svg-icons';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FontAwesomeModule,
  ]
})
export class FontawesomeModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faHeart,
      faUser,
      faLock,
      faArrowRightFromBracket,
      faSave,
      faCircleCheck,
      faCirclePlus,
      faXmarkCircle,
      faPlus,
      faTrashCan,
      faPenToSquare,
      faPen,
      faUserPen,
      faEdit,
      faAngleDown,
      faAngleRight,
      faInstagram,
      faFacebook,
      faTwitter,
      faLocation,
      faMapLocation,
      faLocationPin,
      faPhone,
      faPhoneAlt,
      faEnvelope,
      faSearch,
      faHome,
      faMessage,
      faBuysellads,
      faFile,
      faDollar,
      faGlobe,
      faTruck,
      faBuilding,
      faLocationDot,
      faCircleLeft,
      faCircleXmark,
      faImage,
      faCloudArrowUp
    );
  }
 }
