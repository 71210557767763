import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import { PostRequestComponent } from '../../post-request/post-request.component';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  submitted = false;
  newsletterFormGroup: FormGroup;
  windowScrolled!: boolean;
  showWhoAreWeList: boolean = false;
  showLinksList: boolean = false;
  showResourcesList: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.newsletterFormGroup = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  @HostListener('window:scroll', [])
  ngOnInit(): void {}

  get f() {
    return this.newsletterFormGroup.controls;
  }

  subscribeNewsletter() {
    this.submitted = true;
    if (this.newsletterFormGroup.invalid) {
      return;
    }

    let mailchimpBody = {
      firstName: 'New',
      lastName: 'Subscriber',
      email: this.newsletterFormGroup.value.email,
    };

    this.apiService.post('/mailChimp/subscribe', mailchimpBody).subscribe({
      next: (res: any) => {
        this.toastrService.success(
          'Congratulations! You are successfully subscribed to our newsletter!'
        );
      },
      error: (err: any) => {
        this.toastrService.error('Something went wrong!');
      },
      complete: () => {
        this.newsletterFormGroup.reset();
      },
    });
  }
  openRequestQuoteDialog(productName: any, sellerCompanyId: any) {
    if (this.authService.isLoggedIn()) {
      this.dialog.open(PostRequestComponent, {
        data: { productName, sellerCompanyId },
      });
    } else {
      this.toastrService.warning('Please sign in first to request your quote!');
      this.router.navigateByUrl('auth/sign-in');
    }
  }

  openWhatsApp() {
    const recipientNumber = '+919289811183';
    const messageText = 'Hi! I need help with your products.';
    const encodedMessage = encodeURIComponent(messageText);
    const url = `https://wa.me/${recipientNumber}/?text=${encodedMessage}`;
    window.open(url, '_blank');
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  toggleList(listName: string) {
    switch (listName) {
      case 'whoAreWe':
        this.showWhoAreWeList = !this.showWhoAreWeList;
        break;
      case 'links':
        this.showLinksList = !this.showLinksList;
        break;
      case 'resources':
        this.showResourcesList = !this.showResourcesList;
        break;
      default:
        console.log('Unknown List Clicked!');
        break;
    }
  }
}
