<div class="col-md-12 deatils">
  <div class="row details_header">
    <div class="col-md-12 details_header_text">
      <h5 class="heading">
        Need Help? <mat-icon mat-dialog-close>highlight_off</mat-icon>
      </h5>

      <span>Manage your account settings and preferences from here.</span>
    </div>
  </div>
  <div class="row details_content">
    <form #supportForm="ngForm" (ngSubmit)="submitIssue()">
      <div class="">
        <mat-label>Subject</mat-label>
        <mat-form-field appearance="outline" floatLabel="always">
          <input
            matInput
            type="text"
            name="title"
            [(ngModel)]="title"
            placeholder="Enter title for your issue"
            required
          />
        </mat-form-field>
        <mat-error
          *ngIf="
            supportForm.controls['title']?.touched &&
            supportForm.controls['title'].errors
          "
        >
          <small *ngIf="supportForm.controls['title'].errors['required']"
            >Title is required!</small
          >
        </mat-error>
      </div>
      <div class="my-4">
        <mat-label>Describe the issue you are facing</mat-label>
        <mat-form-field appearance="outline" floatLabel="always">
          <textarea
            matInput
            type="text"
            name="description"
            [(ngModel)]="description"
            placeholder="Enter description for your issue in brief"
            required
          ></textarea>
        </mat-form-field>
        <mat-error
          *ngIf="
            supportForm.controls['description']?.touched &&
            supportForm.controls['description'].errors
          "
        >
          <small *ngIf="supportForm.controls['description'].errors['required']"
            >Description is required!</small
          >
        </mat-error>
      </div>
      <div class="scrshot">
        <img
          [src]="imageUrl ? imageUrl : '/assets/images/placeholder.png'"
          alt="screenshot-image"
        />
      </div>
      <div class="col-md-12 link">
        <label for="file-upload">
          <mat-icon>link</mat-icon>
        </label>
        <input
          type="file"
          id="file-upload"
          accept="image/*"
          (change)="uploadFiles($event)"
        />
        <span>Click here to add any supporting screenshot of the issue.</span>
      </div>
      <div class="col-md-12">
        <div class="button-save">
          <button type="submit" class="Submit" mat-raised-button>
            Submit<mat-icon>arrow_forward</mat-icon>
          </button>
          <div *ngIf="isLoading" class="loading-spinner">
            <mat-spinner diameter="30"></mat-spinner>
          </div>
        </div>
      </div>
    </form>
    <!-- <div class="col-md-12 contact">
      <img src="/assets/images/support.svg" alt="support-image">
      <h5>Facing A Problem? Talk to real Humans, not Robots</h5>
      <span>Buy your favorite products in <span class="bulk">Bulk</span></span>
    </div>
    <div class="col-md-12">
      <div class="support-action">
        <button mat-raised-button>CALL US</button>
        <button mat-raised-button>CHAT WITH US</button>
        <button mat-raised-button>EMAIL US</button>
      </div>
    </div> -->
  </div>
</div>
