<div class="row card-row">
  <div class="col-md-3 mx-4" *ngFor="let item of items">
    <div class="card">
      <img [src]="item?.image" class="card-img-top" alt="image">
      <div class="card-body">
        <mat-form-field appearance="outline">
          <mat-label>{{label}}</mat-label>
          <input matInput [value]="item?.title" class="text-uppercase" disabled>
        </mat-form-field>
        <div class="status">
          <mat-label class="pt-1">{{statusLabel}}</mat-label>
          <mat-slide-toggle [checked]="item?.isActive" (change)="onChange(item?.id)"
            matTooltip="Disable"></mat-slide-toggle>
        </div>
        <div class="desc">
          <p class="card-title">{{descTitle}}</p>
          <p class="card-text">{{item?.description}}</p>
        </div>
      </div>
    </div>
  </div>
</div>