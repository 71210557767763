<div class="container" id="postRequestDialog">
  <div class="row">
    <div class="col-md-11 head_wrapper">
      <div class="heading" *ngIf="sharedData.sellerCompanyId == null">
        <h5 class="mat-dialog-title text-capitalize">
          Tell Us! Your Requirement.
          <mat-icon class="d-none" mat-dialog-close>highlight_off</mat-icon>
        </h5>
        <p>Best Pricing Guaranteed!</p>
      </div>
      <div class="heading" *ngIf="sharedData.sellerCompanyId !== null">
        <h5 class="mat-dialog-title text-capitalize">
          Send Enquiry
          <mat-icon class="d-none" mat-dialog-close>highlight_off</mat-icon>
        </h5>
        <div class="company-details">
          <mat-label><strong>To:</strong></mat-label>
          <img
            [src]="
              companyDetails?.companyLogo
                ? companyDetails?.companyLogo
                : '/assets/images/company-logo-placeholder.png'
            "
            alt="logo"
          />

          <span>{{ companyDetails?.companyName | uppercase }}</span>
        </div>
      </div>
    </div>
    <div class="col-md-1 icon">
      <mat-icon mat-dialog-close>highlight_off</mat-icon>
    </div>
  </div>
  <mat-dialog-content>
    <form #postRequestForm="ngForm">
      <div class="row">
        <mat-label><strong>*</strong>Product Name</mat-label>
        <mat-form-field class="col-md-12 w-100" appearance="outline">
          <input
            matInput
            type="text"
            name="productName"
            [(ngModel)]="postRequest.userData.productName"
            autocomplete="off"
            required
          />
        </mat-form-field>
        <mat-error
          *ngIf="
            postRequestForm.controls['productName']?.dirty ||
            postRequestForm.controls['productName']?.touched
          "
        >
          <small
            *ngIf="postRequestForm.controls['productName']?.errors?.['required']"
            >Product Name is Required!</small
          >
        </mat-error>
      </div>
      <div class="row input">
        <div class="col-md-6">
          <mat-label
            ><strong>*</strong>Purchase Quantity<small
              >(in numbers only)</small
            ></mat-label
          >
          <mat-form-field appearance="outline" class="col-md-6 input-field">
            <input
              matInput
              type="text"
              name="quantityValue"
              required
              [(ngModel)]="postRequest.userData.quantityValue"
              (keydown)="allowNumbersOnly($event)"
            />
            <mat-select
              placeholder="Unit"
              name="quantityUnit"
              [(value)]="postRequest.userData.quantityUnit"
            >
              <mat-option
                *ngFor="let unit of quantityUnit"
                [value]="unit.parameterValue"
              >
                {{ unit.parameterValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              postRequestForm.controls['quantityValue']?.dirty ||
              postRequestForm.controls['quantityValue']?.touched
            "
          >
            <small
              *ngIf="postRequestForm.controls['quantityValue']?.errors?.['required']"
              >Product Quantity is Required!</small
            >
          </mat-error>
        </div>
        <div class="col-md-6">
          <mat-label><strong>*</strong>Trading Condition</mat-label>
          <mat-form-field appearance="outline" class="col-md-6 input-field">
            <mat-select
              class="tradingConditions"
              name="tradingConditions"
              [(value)]="postRequest.userData.tradingConditions"
              required
            >
              <ng-container *ngFor="let condition of tradingCondition">
                <mat-option [value]="condition.parameterValue">
                  {{ condition.parameterValue }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row input">
        <div class="col-md-6">
          <mat-label
            ><strong>*</strong>Target Unit Price<small
              >(in numbers only)</small
            ></mat-label
          >
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="input-field"
          >
            <input
              matInput
              type="text"
              name="targetUnitPrice"
              required
              [(ngModel)]="postRequest.userData.targetUnitPrice"
              autocomplete="off"
              (keydown)="allowNumbersOnly($event)"
            />
          </mat-form-field>
          <mat-error
            *ngIf="
              postRequestForm.controls['targetUnitPrice']?.dirty ||
              postRequestForm.controls['targetUnitPrice']?.touched
            "
          >
            <small
              *ngIf="postRequestForm.controls['targetUnitPrice']?.errors?.['required']"
              >Target Unit Price is Required!</small
            >
          </mat-error>
        </div>
        <div class="col-md-6">
          <mat-label
            ><strong>*</strong>Maximum Budget<small
              >(in numbers only)</small
            ></mat-label
          >
          <mat-form-field
            appearance="outline"
            floatLabel="always"
            class="input-field"
          >
            <input
              matInput
              type="text"
              name="maximumBudget"
              required
              [(ngModel)]="postRequest.userData.maxBudget"
              autocomplete="off"
              (keydown)="allowNumbersOnly($event)"
            />
            <mat-select
              name="currency"
              [(value)]="postRequest.userData.unitPriceCurrency"
            >
              <mat-option
                *ngFor="let currency of currencyData"
                [value]="currency.currencyCode"
              >
                {{ currency.currencyCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error
            *ngIf="
              postRequestForm.controls['maximumBudget']?.dirty ||
              postRequestForm.controls['maximumBudget']?.touched
            "
          >
            <small
              *ngIf="postRequestForm.controls['maximumBudget']?.errors?.['required']"
              >Maximum Budget is Required!</small
            >
          </mat-error>
        </div>
      </div>
      <div class="row">
        <mat-label>Description</mat-label>
        <mat-form-field
          class="col-md-12 w-100"
          appearance="outline"
          floatLabel="always"
        >
          <textarea
            matInput
            name="desc"
            cols="30"
            rows="5"
            [(ngModel)]="postRequest.userData.description"
            autocomplete="off"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="text-end my-2">
        <button
          class="button-orange"
          mat-raised-button
          mat-dialog-close
          [disabled]="postRequestForm.invalid"
          (click)="postOrderRequest()"
        >
          Post Your Enquiry
          <img src="/assets/images/user-profile/ArrowRight.png" alt="" />
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
