import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailUsComponent } from '../email-us/email-us.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openEmailUs(){
      const dialogRef = this.dialog.open(EmailUsComponent, {
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');  
      });
    }

  }

