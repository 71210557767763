<div class="back-img">
    <div class="container">
        <div class="row">
            <div class="col-md-12 error-content">
                <h3>Error</h3>
                <h2>404</h2>
                <p>Alien Abduction – Page Lost. Please try again after some time.</p>
                <span><button class="btn">
                    Go To Homepage      
                  <img src="../../../assets/images/user-profile/ArrowRight.png" alt="" />
                </button>
            </span>
            </div>
        </div>
    </div>
  
</div>

