export interface Country {
  value: string;
  countryName: string;
  imgUrl: string;
}
interface TrendingProductList {
  title: string;
}

export interface Language {
  value: string;
  languageName: string;
  imgUrl: string;
}

export interface Currency {
  id: number;
  currencyCode: string;
  exchangeRate: number;
}
export interface Keyword {
  keywordId: number;
  keywordName: string;
}

export class Header {
  trendingProductList: TrendingProductList[] = [
    {
      title: 'Machines & Equipment',
    },
    {
      title: 'Textile Machines',
    },
    {
      title: 'Home Interior',
    },
    {
      title: 'Men’s Clothing',
    },
    {
      title: 'Printing Machinery',
    },
    { title: 'laptop' },
  ];

  countries: Country[] = [
    { value: 'IN', countryName: 'India', imgUrl: 'assets/images/flag-in.jpg' },
    {
      value: 'CHI',
      countryName: 'China',
      imgUrl: 'assets/images/flag-chi.jpg',
    },
    { value: 'US', countryName: 'USA', imgUrl: 'assets/images/flag-usa.jpg' },
  ];

  languages: Language[] = [
    {
      value: 'en',
      languageName: 'English',
      imgUrl: 'assets/images/flag-in.jpg',
    },
    {
      value: 'hin',
      languageName: 'Hindi',
      imgUrl: 'assets/images/flag-in.jpg',
    },
  ];

  currencies: Currency[] = [{ id: 1, currencyCode: 'USD', exchangeRate: 1.0 }];
}
