<div class="row support">
  <div class="col-md-12 contact">
    <img src="../../../assets/images/landing-page/Frame.png" alt="" />
    <h5>Facing A Problem? Talk to real Humans, not Robots</h5>
    <span>Buy your favorite products in <span class="bulk">Bulk</span></span>
  </div>
  <div class="col-md-12">
    <div class="call_email">
      <button mat-raised-button><a href="tel:+919289811183">CALL US</a></button>
      <!-- <button mat-raised-button>CHAT WITH US</button> -->
      <button mat-raised-button (click)="openEmailUs()">EMAIL US</button>
    </div>
  </div>
</div>
