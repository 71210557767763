import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule } from 'primeng/chart';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { RouterModule } from '@angular/router';
import { FontawesomeModule } from './fontawesome/fontawesome.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MaterialModule } from './material/material.module';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { SharedModule } from './shared/shared.module';
import { HttpAuthInterceptor } from './services/http-auth.interceptor';
import { ProductPageComponent } from './pages/product-page/product-page.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ViewProductComponent } from './pages/view-product/view-product.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    PageNotFoundComponent,
    ProductPageComponent,
    ViewProductComponent,
  ],
  imports: [
    BrowserModule,
    ChartModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    FontawesomeModule,
    MaterialModule,
    CarouselModule,
    NgxPaginationModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    MatStepperModule,
    MatSelectCountryModule.forRoot('en'), // you can use 'br' | 'de' | 'en' | 'es' | 'fr' | 'hr' | 'hu' | 'it' | 'nl' | 'pt' -->
    ToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
