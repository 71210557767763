import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-send-email-quote',
  templateUrl: './send-email-quote.component.html',
  styleUrls: ['./send-email-quote.component.scss'],
})
export class SendEmailQuoteComponent implements OnInit, OnDestroy {
  email: string = '';
  subject: string = '';
  text: string = '';
  emailBodyNote: string =
    'Note: Please login to your dashboard to view all conversation.';
  lead: any;
  currentUser: any;
  userId: any;
  userCompanyName: any;
  userCompanyId: any;
  catalougeUrls: any;
  selectedCatalogues: string[] = [];
  filteredTransactions: any;
  private subscriptions: Subscription[] = [];

  @ViewChild('sendEmailForm', { static: false }) sendEmailForm!: NgForm;
  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public sharedData: any
  ) {
    this.lead = sharedData.lead;
    this.currentUser = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(this.currentUser);
    this.userId = this.currentUser.userId;
    this.userCompanyName = this.currentUser.company.companyName;
    this.userCompanyId = this.currentUser.company.companyId;
  }

  ngOnInit(): void {
    if (this.sharedData.userType === 'buyer') {
      this.filteredTransactions = this.sharedData.order.transactions.filter(
        (transaction: any) =>
          transaction.companyId ===
            this.sharedData.order.orderRequest.sellerCompanyId ||
          transaction.repliedTo ===
            this.sharedData.order.orderRequest.sellerCompanyId
      );
      this.email = this.filteredTransactions[0].fromEmail;
    }

    if (this.sharedData.userType === 'seller') {
      this.filteredTransactions = this.sharedData.order.transactions.filter(
        (transaction: any) =>
          transaction.companyId ===
            this.sharedData.order.orderWithUser.orderRequest.sellerCompanyId ||
          transaction.repliedTo ===
            this.sharedData.order.orderWithUser.orderRequest.sellerCompanyId
      );
      this.email = this.filteredTransactions[0].toEmail;
    }
    console.log('this.filteredTransactions: ', this.filteredTransactions);

    this.subject = `RE: ${this.filteredTransactions[0].subject}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  get fControls() {
    return this.sendEmailForm?.controls || {};
  }
  getCatalouge() {
    this.subscriptions.push(
      this.apiService.get(`/catalog/${this.userCompanyId}`).subscribe({
        next: (res: any) => {
          this.catalougeUrls = res.imageUrls;
        },
        error: (err) => {
          console.error(err.error);
        },
      })
    );
  }
  onCatalogueSelected(catalogueUrl: string) {
    if (this.selectedCatalogues.includes(catalogueUrl)) {
      // If the URL is already selected, remove it from the array
      this.selectedCatalogues = this.selectedCatalogues.filter(
        (url) => url !== catalogueUrl
      );
    } else {
      // If the URL is not selected, add it to the array
      this.selectedCatalogues.push(catalogueUrl);
    }
  }
  sendEmail() {
    let params = {
      to: this.email,
      subject: this.subject,
      text: `${this.text}. \n ${this.emailBodyNote}`,
    };
    this.subscriptions.push(
      this.apiService.post('/send-email', params).subscribe({
        next: (res: any) => {
          this.toastr.success('Email Sent Successfully!');
        },
        error: (err) => {
          console.error('Email not sent!');
        },
        complete: () => {
          this.addEmailTransaction();
          this.dialog.closeAll();
        },
      })
    );
  }

  addEmailTransaction() {
    if (this.sharedData.userType === 'buyer') {
    }
    let params = {
      orderId: this.filteredTransactions[0].orderId,
      companyId: this.userCompanyId, //Transaction added by companyId
      companyName: this.userCompanyName, //Transaction added by companyName
      userId: this.userId,
      replied: true,
      repliedTo: this.filteredTransactions[0].companyId, //repliedTo companyId
      status: this.filteredTransactions[0].status,
      toEmail: this.email,
      fromEmail:
        this.sharedData.userType === 'seller'
          ? this.filteredTransactions[0].fromEmail
          : this.filteredTransactions[0].toEmail,
      subject: this.subject,
      emailContent: this.text,
      attachments: null,
    };

    this.subscriptions.push(
      this.apiService.post('/transactions/addTransaction', params).subscribe({
        next: (res: any) => {
          this.toastr.success('Email Transaction Added Successfully!');
        },
        error: (err) => {
          this.toastr.error(err.error.message);
        },
        complete: () => {},
      })
    );
  }
}
