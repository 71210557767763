import { Component, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';
import emailjs from '@emailjs/browser';
import { environment } from 'src/environments/environment';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-email-us',
  templateUrl: './email-us.component.html',
  styleUrls: ['./email-us.component.scss'],
})
export class EmailUsComponent implements OnInit {
  currentUser: any;
  userCompanyId: any;
  companyName: any;
  userId: any;
  imageUrl: string = '';
  selectedFiles: File[] = [];
  title: string = '';
  description: string = '';
  publicKey = environment.emailJs.publicKey;
  serviceId = environment.emailJs.serviceId;
  templateId = environment.emailJs.templateId;
  isLoading: boolean = false;
  @ViewChild('supportForm') supportForm!: NgForm;

  constructor(private apiService: ApiService, private toastr: ToastrService) {
    this.currentUser = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(this.currentUser);
    this.userCompanyId = this.currentUser?.company.companyId;
    this.userId = this.currentUser?.userId;
    this.companyName = this.currentUser?.company.companyName;
  }

  ngOnInit(): void {}
  uploadFiles(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files) {
      this.selectedFiles = Array.from(inputElement.files);
    }
    this.fileUpload();
  }
  fileUpload() {
    let formData = new FormData();
    this.selectedFiles.forEach((file) => {
      formData.append('file', file);
    });

    this.apiService.uploadUserFile(`/company/uploadFile`, formData).subscribe({
      next: (res: any) => {
        console.log('Image Attached!');
        let fileUrl = res;
        this.imageUrl = fileUrl.map((index: any) => index.url);
      },
      error: (err: any) => {
        this.toastr.error(err.error.message);
        console.error(err.error.message);
      },
      complete: () => {
        this.selectedFiles = [];
      },
    });
  }

  submitIssue() {
    this.isLoading = true;

    const templateParams = {
      from_name: this.companyName,
      to_name: 'BBI Support Team',
      subject: this.title,
      message: this.description,
      scrshot: this.imageUrl,
    };

    emailjs
      .send(this.serviceId, this.templateId, templateParams, this.publicKey)
      .then(
        (response) => {
          this.toastr.success('Your support request submitted!');
          this.supportForm.reset();
          this.imageUrl = '';
          this.isLoading = false;
        },
        (err) => {
          console.log('Mail Sent Failed!', err);
          this.isLoading = false;
        }
      );
  }
}
