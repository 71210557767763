<app-header></app-header>
<div id="product-search">
  <div class="row product-search-content">
    <div class="col-md-2 sidebar">
      <!-- <div class="related-searches">
        <span>Related Searches</span>
        <ul *ngFor="let items of productPage.relatedSearches">
          <li>{{ items.item }}</li>
        </ul>
        <span><a href="">View More</a></span>
      </div> -->
      <div class="trending-searches">
        <span>Trending Searches <mat-icon>trending_up</mat-icon></span>
        <ul *ngFor="let trendingSearch of productPage.trendingSearches">
          <li (click)="onKeywordClick(trendingSearch.keyword)">
            {{ trendingSearch.keyword }}
          </li>
        </ul>
        <!-- <span><a>View More</a></span> -->
      </div>
    </div>
    <section class="col-md-10 product-cards">
      <div class="trending-searches_mobile d-none">
        <div class="heading d-flex">
          <img class="me-1" src="/assets/images/TrendUp.png" alt="image" />
          <h6>Trending Searches</h6>
        </div>
        <div class="chips-container">
          <mat-chip-list
            class="trending-search-list"
            cdkDropList
            cdkDropListOrientation="horizontal"
          >
            <mat-chip
              class="trending-search-chip"
              cdkDrag
              *ngFor="let trendingSearch of productPage.trendingSearches"
              (click)="onKeywordClick(trendingSearch.keyword)"
            >
              {{ trendingSearch.keyword }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>

      <div *ngIf="!isLoading && allProducts?.length; else noProductFound">
        <div class="header">
          <p class="found">{{ allProducts?.length }} products found</p>
        </div>
        <div class="row product">
          <div
            class="col-md-3 product-item"
            *ngFor="
              let product of allProducts
                | paginate : { itemsPerPage: itemPerPage, currentPage: pageNo };
              let i = index
            "
          >
            <app-product-card
              [productCard]="product"
              (onClick)="viewProduct($event)"
              (onClickRequest)="openRequestQuote($event)"
            ></app-product-card>
          </div>
        </div>
      </div>
      <div *ngIf="isLoading" class="loading-spinner">
        <mat-spinner diameter="70"></mat-spinner>
      </div>
      <ng-template #noProductFound>
        <div *ngIf="!isLoading" class="no-data-found">
          <span
            ><img src="/assets/images/products/no-product-found.png" alt=""
          /></span>
          <h5>Sorry! No Product Found</h5>
          <span routerLink="/"
            ><button type="submit" class="button-orange" mat-raised-button>
              <mat-icon>arrow_back</mat-icon>
              Go Back To Home
            </button>
          </span>
        </div>
      </ng-template>

      <div id="product-page">
        <!-- <mat-form-field appearance="fill">
          <mat-label>Select Page Size</mat-label>
          <mat-select
            [(value)]="pageSize"
            (valueChange)="onPageSizeChange($event)"
          >
            <mat-option *ngFor="let count of pageSizeOptions" [value]="count">{{
              count
            }}</mat-option>
          </mat-select>
        </mat-form-field> -->
        <pagination-controls
          [maxSize]="6"
          [directionLinks]="true"
          [autoHide]="true"
          previousLabel="Prev"
          nextLabel="Next"
          (pageChange)="onPageDataChange($event)"
        >
        </pagination-controls>
      </div>
    </section>
  </div>
</div>
<app-support></app-support>
<app-footer></app-footer>
