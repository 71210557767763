import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-cards',
  templateUrl: './custom-cards.component.html',
  styleUrls: ['./custom-cards.component.scss'],
})
export class CustomCardsComponent implements OnInit {
  @Input() label: string = '';
  @Input() statusLabel: string = '';
  @Input() descTitle: string = '';
  @Input() items: Item[] = [];
  @Output() onStatusChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
  onChange(id?: any) {
    this.onStatusChange.emit(id);
  }
}
interface Item {
  id?: string;
  image?: string;
  title?: string;
  isActive?: boolean;
  description?: string;
}
