import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-carousel-product-card',
  templateUrl: './carousel-product-card.component.html',
  styleUrls: ['./carousel-product-card.component.scss']
})
export class CarouselProductCardComponent implements OnInit {

  @Input() title?: string = 'loved product'
  @Input() routerLink?: string;
  @Input() productCard: any[] = [];
  @Output() onClick = new EventEmitter<any>();
  @Output() onClickView = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  viewProduct(id?: any) {
    this.onClick.emit(id);  
  }
  viewAll(id?:any){
    this.onClickView.emit(id); 
  }

  products: OwlOptions = {
    autoplay: true,
    loop: true,
    margin: 10,
    dots: false,
    navSpeed: 500,
    navText: ['&#8249', '&#8250;'],
    autoHeight: true,
    autoWidth: true,  
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 5,
      },
    },
    nav: true,
  };

}
