import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatDialog } from '@angular/material/dialog';
import { PostRequestComponent } from 'src/app/shared/post-request/post-request.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiService } from 'src/app/services/api.service';
import { filter } from 'rxjs';
import { ProductPage } from './product-page';
import { AuthService } from 'src/app/services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss'],
})
export class ProductPageComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator!: MatPaginator;
  page!: PageEvent;
  itemPerPage: number = 12;
  pageNo: number = 1;
  pageSize: number = 100000;
  // pageSizeOptions: number[] = [
  //   12, 24, 48, 60, 72, 84, 96, 108, 200, 400, 800, 1600
  // ];
  productPage: ProductPage;
  allProducts: any;
  categoryProducts: any;
  productsByProductId: any;
  categoryId: any;
  productId: any;
  companyId: any;
  urlString: any;
  isLoading: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {
    this.productPage = new ProductPage();
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        // Logic to fetch and display data based on the updated URL
        this.getUrlString();
      });

    // Initial load of data
    this.getUrlString();
  }

  ngOnInit(): void {
    // this.route.queryParams.subscribe((params) => {
    //   console.log('params:', params);

    //   // Access query parameters
    //   const param1 = params['showProductBy'];
    //   const param2 = params['value'];

    //   // Do something with the parameters
    //   console.log('Param1:', param1);
    //   console.log('Param2:', param2);
    // });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions to avoid memory leaks
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getUrlString() {
    this.route.params.subscribe((params) => {
      this.urlString = params['id'];
    });
    if (isNaN(this.urlString)) {
      this.getAllProductDetails(this.urlString);
    } else {
      this.getProductDetailsByCategoryId(this.urlString);
      this.getProductDetailsByProductId(this.urlString);
    }
  }

  getAllProductDetails(urlString: any) {
    this.isLoading = true;

    this.subscriptions.push(
      this.apiService.get(`/productDetails/size/${this.pageSize}`).subscribe({
        next: (res: any) => {
          if (urlString == 'all-product') {
            this.allProducts = res;
          } else {
            this.allProducts = res.filter((product: any) =>
              product.productName
                .toLowerCase()
                .includes(urlString.toLowerCase())
            );
            this.cdr.detectChanges(); // Manually trigger change detection
          }
          this.isLoading = false;
        },
        error: (err: any) => {
          console.error(err.error.message);
          this.isLoading = false;
        },
      })
    );
  }

  getProductDetailsByCategoryId(id: any) {
    this.subscriptions.push(
      this.apiService.get(`/productDetails/ByCategoryId/${id}`).subscribe({
        next: (res: any) => {
          this.allProducts = res;
          this.cdr.detectChanges(); // Manually trigger change detection
        },
        error: (err: any) => {
          console.error(err.error.message);
        },
      })
    );
  }

  getProductDetailsByProductId(id: any) {
    this.subscriptions.push(
      this.apiService
        .get(`/productDetails/getProductDetailsByProductId/${id}`)
        .subscribe({
          next: (res: any) => {
            this.allProducts = res;
            this.cdr.detectChanges(); // Manually trigger change detection
          },
          error: (err: any) => {
            console.error(err.error.message);
          },
        })
    );
  }
  onKeywordClick(keyword: any) {
    this.router.navigateByUrl(`/products/${keyword}`);
  }

  viewProduct(productId: any) {
    this.router.navigateByUrl(`/product/${productId}`);
  }
  // onPageSizeChange(event: any): void {
  //   this.pageSize = event;
  //   this.getAllProductDetails(this.urlString);
  // }

  onPageDataChange(event: any) {
    this.pageNo = event;
  }
  openRequestQuote(event: any) {
    const sellerCompanyId = event.id;
    const productName = event.name;
    if (this.authService.isLoggedIn()) {
      const dialogRef = this.dialog.open(PostRequestComponent, {
        data: { sellerCompanyId, productName },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    } else {
      this.toastr.warning('Please sign in first to request your quote!');
      this.router.navigateByUrl('auth/sign-in');
    }
  }

  similarProduct: OwlOptions = {
    autoplay: true,
    loop: true,
    margin: 10,
    dots: false,
    navSpeed: 500,
    navText: ['&#8249', '&#8250;'],
    autoHeight: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 6,
      },
    },
    nav: true,
  };
}
