import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash-cards',
  templateUrl: './dash-cards.component.html',
  styleUrls: ['./dash-cards.component.scss'],
})
export class DashCardsComponent implements OnInit {
  @Input() cardItems: Item[] = [];
  constructor() {}

  ngOnInit(): void {}
}

interface Item {
  label?: string;
  class?: string;
  image?: string;
  count?: string;
  days?: string;
}
