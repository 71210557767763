import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PostRequest } from './post-request';
import { Currency } from '../reusable/interface/currency';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-post-request',
  templateUrl: './post-request.component.html',
  styleUrls: ['./post-request.component.scss'],
})
export class PostRequestComponent implements OnInit {
  currentUser: any;
  postRequest: PostRequest;
  currency: Currency;
  PostRequestForm!: NgForm;
  allParameters: any;
  quantityUnit: any;
  tradingCondition: any;
  currencyData: any;
  sellerCompanyId: any;
  companyDetails: any;
  submitted = false;
  ifError = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public sharedData: any
  ) {
    this.postRequest = new PostRequest();
    this.currency = new Currency();
  }

  ngOnInit(): void {
    this.currentUser = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(this.currentUser);
    this.postRequest.userData.companyId = this.currentUser?.company.companyId;
    this.postRequest.userData.userId = this.currentUser.userId;
    this.postRequest.userData.productName =
      this.sharedData.productName !== null ? this.sharedData.productName : '';
    this.postRequest.userData.sellerCompanyId =
      this.sharedData.sellerCompanyId !== null
        ? this.sharedData.sellerCompanyId
        : null;
    this.postRequest.userData.orderedCountryId = this.currentUser.country;
    if (this.sharedData.sellerCompanyId !== null) {
      this.getCompanyById(this.sharedData.sellerCompanyId);
    }
    this.getAllParameters();
    this.getAllCurrency();
  }

  getCompanyById(companyId: any) {
    this.apiService.get(`/company/getCompanyById/${companyId}`).subscribe({
      next: (res: any) => {
        this.companyDetails = res;
      },
      error: (err: any) => {
        console.error(err.error);
      },
    });
  }

  getAllParameters() {
    this.subscriptions.push(
      this.apiService.get(`/parameters`).subscribe({
        next: (res: any) => {
          this.allParameters = res;
          let quantityUnit = this.allParameters;
          this.quantityUnit = quantityUnit.filter(
            (unit: any) => unit.parameterName == 'quantity'
          );
          let tradingCondition = this.allParameters;
          this.tradingCondition = tradingCondition.filter(
            (tradingCondition: any) =>
              tradingCondition.parameterName == 'tradingCondition'
          );
        },
        error: (err) => {
          console.error(err.error);
        },
      })
    );
  }

  getAllCurrency() {
    this.apiService.get('/api/currencyList').subscribe({
      next: (res: any) => {
        this.currencyData = res;
      },
      error: (err) => {
        console.log(err.error.message);
      },
    });
  }

  postOrderRequest() {
    let params = this.postRequest.userData;

    this.subscriptions.push(
      this.apiService.post('/orderRequest/addOrderRequest', params).subscribe({
        next: (res: any) => {
          this.toastr.success('Your Enquiry Sent Successfully!');
        },
        error: (err) => {
          console.error(err.error.message);
        },
      })
    );
  }

  allowNumbersOnly(event: any) {
    this.authService.allowedNumbersOnly(event);
  }
}
