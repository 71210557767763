export interface TradingCondition {
  value: string;
  viewValue: string;
}
export interface unit {
  value: string;
  viewValue: string;
}

export class PostRequest {
  userData: {
    productName: string;
    tradingConditions: any;
    quantityValue: any;
    quantityUnit: any;
    targetUnitPrice: any;
    maxBudget: any;
    unitPriceCurrency: string;
    description: any;
    orderedCountryId: number | undefined;
    orderedState: number | null;
    orderedCity: number | null;
    companyId: number | undefined;
    userId: number | undefined;
    sellerCompanyId: number | undefined;
  } = {
    productName: '',
    quantityValue: '',
    quantityUnit: 'Piece(S)',
    tradingConditions: 'FOB',
    targetUnitPrice: '',
    maxBudget: '',
    unitPriceCurrency: 'USD',
    description: '',
    orderedCountryId: undefined,
    orderedState: null,
    orderedCity: null,
    companyId: undefined,
    userId: undefined,
    sellerCompanyId: undefined,
  };
}
