<div class="search">
  <input
    matInput
    type="text"
    placeholder="Search products for buying and selling here..."
    [formControl]="searchControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <button type="button" class="button-primary" (click)="onSearch(searchControl.value)">
    <fa-icon [icon]="['fas', 'search']"></fa-icon>
    <span>Search</span>
  </button>
</div>
