<div class="container" id="sendQuoteDialog">
  <div class="row">
    <div class="col-md-11 proposal">
      <h5 class="mat-dialog-title text-capitalize">Send E-Mail Proposal</h5>
    </div>
    <div class="col-md-1 close">
      <mat-icon
        mat-dialog-close
        aria-hidden="false"
        aria-label="Example home icon"
        >highlight_off</mat-icon
      >
    </div>
  </div>
  <mat-dialog-content>
    <form #sendEmailForm="ngForm" (ngSubmit)="sendEmail()">
      <div class="row form-group_wrapper">
        <div class="col-md-12 form-group">
          <div class="row align-items-center">
            <div class="col-md-2">
              <mat-label>To:</mat-label>
            </div>
            <div class="col-md-10">
              <mat-form-field class="" appearance="outline" floatLabel="always">
                <input
                  matInput
                  type="email"
                  name="email"
                  [(ngModel)]="email"
                  disabled
                />
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-md-12 form-group">
          <div class="row align-items-center">
            <div class="col-md-2">
              <mat-label>Subject:</mat-label>
            </div>
            <div class="col-md-10">
              <mat-form-field class="" appearance="outline" floatLabel="always">
                <input
                  matInput
                  class="text-capitalize"
                  type="text"
                  name="subject"
                  [(ngModel)]="subject"
                  disabled
                />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-md-12 form-group">
          <div class="row">
            <div class="col-md-2">
              <mat-label>Body:</mat-label>
            </div>
            <div class="col-md-10">
              <mat-form-field class="" appearance="outline" floatLabel="always">
                <textarea
                  matInput
                  type="text"
                  name="text"
                  [(ngModel)]="text"
                  rows="5"
                  placeholder="Write your proposal here..."
                  required
                ></textarea>
              </mat-form-field>
              <mat-error
                *ngIf="fControls['text']?.touched && fControls['text'].errors"
              >
                <small *ngIf="fControls['text'].errors['required']"
                  >Write some description.</small
                >
              </mat-error>
            </div>
          </div>
        </div>
        <!-- <div class="catalogue">
        <div class="col-md-12 my-3">
          <h6>Choose Catalouges from below to attach.</h6>
        </div>
        <div class="row mb-3">
          <div
            class="col-md-2 added-catalogue"
            *ngFor="let catalogue of catalougeUrls; let i = index"
          >
            <div class="image">
              <iframe
                [src]="catalogue.imageUrl | safe : 'resourceUrl'"
                frameborder="0" class="max-width-100"
              ></iframe>
            </div>

            <div class="mb-3">
              <mat-checkbox (change)="onCatalogueSelected(catalogue.imageUrl)">
                Catalogue {{ i + 1 }}
              </mat-checkbox>
            </div>
          </div>
        </div>
       </div> -->
      </div>
      <div class="button-save">
        <button class="send" mat-raised-button>
          Send E-Mail<mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
