import {
  ChangeDetectorRef,
  Component,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PostRequestComponent } from '../../post-request/post-request.component';
import { ApiService } from 'src/app/services/api.service';
import {
  Observable,
  ReplaySubject,
  Subject,
  Subscription,
  map,
  startWith,
  take,
  takeUntil,
} from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { Currency, Header, Keyword } from './header';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          width: '100%', // Adjust this value as needed
        })
      ),
      state(
        'closed',
        style({
          width: '0px',
        })
      ),
      transition('open => closed', [animate('0.3s')]),
      transition('closed => open', [animate('0.3s')]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  categoryList: any[] = [];
  selectedCategoryId: any[] = [];
  allCategory: any[] = [];
  isLoggedIn: any;
  userData: any;
  userCompanyId: any;
  userId: any;
  currentUser: any;
  companyLogo: string | undefined;
  isSticky: boolean = false;
  isSideMenuOpen = false;
  header: Header;
  signUpVideoLink: string = 'https://www.youtube.com/watch?v=_wMXzxiCNVI';
  keywordCtrl = new FormControl('');
  filteredKeywords: Observable<Keyword[]>;
  keywords: Keyword[] = [];
  searchedKeyword: any;
  private subscriptions: Subscription[] = [];
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  defaultLanguage: string = 'English';
  selectedCurrency: string = 'USD';
  currencyPlaceholder: string = 'USD';
  exchangeRate: number = 1.0;
  dollarRate: number = 1.0; // Add 1 rs extra
  protected currencies: Currency[] = [];

  /** control for the selected currency */
  public currencyCtrl: FormControl = new FormControl('USD');

  /** control for the MatSelect filter keyword */
  public currencyFilterCtrl: FormControl = new FormControl('');

  /** list of currencies filtered by search keyword */
  public filteredCurrency: ReplaySubject<Currency[]> = new ReplaySubject<
    Currency[]
  >(1);

  @ViewChild('currencySelect', { static: true }) currencySelect!: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private sharedService: SharedService
  ) {
    this.header = new Header();
    this.currentUser = localStorage.getItem('currentUser');
    this.currentUser = JSON.parse(this.currentUser);
    this.userId = this.currentUser?.userId;
    this.filteredKeywords = this.keywordCtrl.valueChanges.pipe(
      startWith(''),
      map((keyword) =>
        keyword ? this._filterKeywords(keyword) : this.keywords.slice()
      )
    );
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    if (this.userId != undefined) {
      this.getUsersByUserId();
    }
    //  this.getCategory();
    this.getAllKeyword();
    this.getCategory();

    this.getDollarRate();
    this.getCurrency();

    // set initial selection
    this.currencyCtrl.setValue('USD');
    // this.setInitialValue();
    // load the initial currency list
    this.filteredCurrency.next(this.currencies?.slice());

    // listen for search field value changes
    this.currencyFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterCurrency();
      });
  }

  ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.checkScroll();
      });
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    // Unsubscribe from all subscriptions to avoid memory leaks
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
  private _filterKeywords(value: string): Keyword[] {
    const filterValue = value.toLowerCase();
    return this.keywords.filter((keyword) =>
      keyword.keywordName.toLowerCase().includes(filterValue)
    );
  }

  /**
   * Sets the initial value after the filteredcurrencies are loaded initially
   */
  protected setInitialValue() {
    this.filteredCurrency
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.currencySelect.compareWith = (cur_1: Currency, cur_2: Currency) =>
          cur_1 && cur_2 && cur_1.id === cur_2.id;
      });
  }

  protected filterCurrency() {
    if (!this.currencyCtrl) {
      return;
    }
    // get the search keyword
    let search = this.currencyFilterCtrl.value;
    if (!search) {
      this.filteredCurrency.next(this.currencies.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the currencies
    this.filteredCurrency.next(
      this.currencies.filter(
        (currency) => currency.currencyCode.toLowerCase().indexOf(search) > -1
      )
    );
  }

  toggleSideMenu() {
    this.isSideMenuOpen = !this.isSideMenuOpen;
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const mobileHeader = document.getElementById('mobile-header');
    if (mobileHeader) {
      if (window.scrollY > mobileHeader.offsetTop) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
      this.cdRef.detectChanges(); // Manually trigger change detection
    }
  }

  getAllKeyword() {
    this.subscriptions.push(
      this.apiService.get('/keywords/getAllKeywords').subscribe({
        next: (res: any) => {
          this.keywords = res;
        },
        error: (err: any) => {
          console.error(err.error.message);
        },
        complete: () => {
          // Additional completion logic if needed
        },
      })
    );
  }

  getUsersByUserId() {
    this.subscriptions.push(
      this.apiService.get(`/user/${this.userId}`).subscribe({
        next: (res: any) => {
          this.userData = res;
          this.companyLogo = this.userData?.company?.companyLogo;
        },
        error: (err) => {
          console.error(err.error);
        },
      })
    );
  }

  getCategory() {
    let pageSize = 10000;
    this.subscriptions.push(
      this.apiService
        .get(`/categories/getAllCategoriesWithoutProducts/${pageSize}`)
        .subscribe({
          next: (res: any) => {
            let categories = res.content;
            this.categoryList = categories.filter(
              (category: any) => category.activeInactive === true
            );
          },
          error: (err) => {
            console.error(err.error.message);
          },
        })
    );
  }

  getCurrency() {
    this.subscriptions.push(
      this.apiService.get(`/api/currencyList`).subscribe({
        next: (res: any) => {
          this.currencies = res;
        },
        error: (err: any) => {
          console.error(err.error.message);
        },
      })
    );
  }

  getSelectedCurrency(currency: any) {
    this.currencyPlaceholder = 'Currency';
    this.selectedCurrency = currency.currencyCode;
    this.sharedService.currencyCode = currency.currencyCode;
    this.sharedService.exchangeRate = currency.exchangeRate;
    // this.sharedService.dollarRate = currency?.exchangeRate + 1;
  }

  getDollarRate() {
    let params = {
      sourceCurrency: 'USD',
      targetCurrency: 'INR',
      amount: 1,
    };

    this.subscriptions.push(
      this.apiService.post(`/api/convert`, params).subscribe({
        next: (res: any) => {
          this.dollarRate = res;

          this.sharedService.dollarRate = this.dollarRate;
        },
        error: (err: any) => {
          console.error(err.error.message);
        },
      })
    );
  }

  viewCategoryProduct(id: any) {
    this.toggleSideMenu();
    this.router.navigateByUrl(`/products/${id}`);
  }
  getCategoryId(id: any) {
    this.selectedCategoryId = id;
  }

  onKeywordSearch() {
    const searchedKeyword = this.keywordCtrl.value;
    this.router.navigateByUrl(`/products/${searchedKeyword}`);

    // const queryParams = {
    //   showProductBy: 'searchedKeyword',
    //   value: searchedKeyword,
    // };

    // this.router.navigate([`/products/${searchedKeyword}`], {
    //   queryParams: queryParams,
    // });
  }
  onLangSelect(event: any) {
    console.log(event.target.value);
  }

  logout() {
    this.authService.logout();
    this.isLoggedIn = false;
  }

  openRequestQuoteDialog(productName: any, sellerCompanyId: any) {
    this.toggleSideMenu();

    if (this.authService.isLoggedIn()) {
      this.dialog.open(PostRequestComponent, {
        data: { productName, sellerCompanyId },
      });
    } else {
      this.toastr.warning('Please sign in first to request your quote!');
      this.router.navigateByUrl('auth/sign-in');
    }
  }
}
