<header id="main-header">
  <div [ngClass]="{ sticky: isSticky }">
    <div class="row header-top">
      <div class="col-md-6 contact">
        <div class="phone">
          <img src="/assets/images/header/PhoneCall.png" alt="phone-icon" />
          <a href="tel:+919289811183">+91-9289811183</a>
        </div>
        <div class="email">
          <img
            src="/assets/images/header/EnvelopeSimpleOpen.png"
            alt="email-icon"
          />
          <a
            href="mailto:info.bulkbuyindia@gmail.com?subject=New Enquiry&body=Hi! I Need help with your products."
            target="_blank"
            >info.bulkbuyindia@gmail.com</a
          >
        </div>
      </div>
      <div
        class="col-md-6 top-header-right"
        [ngClass]="{ 'flex-end': isLoggedIn }"
      >
        <div class="watch-video" *ngIf="!isLoggedIn">
          <mat-label>Need help with sign up?</mat-label>
          <a [href]="signUpVideoLink" target="_blank"
            >Watch Video <mat-icon>play_circle_filled</mat-icon></a
          >
        </div>
        <div class="country-language">
          <div class="country">
            <mat-label>Sell From:</mat-label>
            <img src="/assets/images/header/IN.png" alt="flag-img" /><span
              >INDIA</span
            >
          </div>

          <div class="language">
            <img src="/assets/images/header/global.png" alt="globe-img" />
            <span>{{ defaultLanguage }} - {{ selectedCurrency }}</span>
          </div>

          <!-- <mat-select [(value)]="countries[0].countryName" disabled>
            <mat-option
              *ngFor="let country of countries"
              [value]="country.countryName"
            >
              <img [src]="country.imgUrl" alt="" />
              <span> {{ country.countryName }}</span>
            </mat-option>
          </mat-select>
         -->
        </div>
      </div>
    </div>
    <div class="row header-middle">
      <div class="col-md-3 logo">
        <img
          class="img-fluid"
          src="/assets/images/bbi-logo.png"
          alt="brand-logo"
          routerLink="/"
        />
      </div>
      <div class="col-md-5 searchbar">
        <div class="row search-item">
          <div class="col-md-2 category">
            <!-- <mat-select
              class="text-capitalize"
              name="category"
              (valueChange)="getCategoryId($event)"
              [(value)]="selectedCategoryId"
              placeholder="All"
            >
              <mat-option
                class="text-capitalize"
                *ngFor="let category of categoryList"
                [value]="category.categoryId"
              >
                {{ category.categoryName }}
              </mat-option>
            </mat-select> -->
          </div>
          <div class="search-wrapper">
            <div class="col-md-9 input">
              <input
                matInput
                [matAutocomplete]="auto"
                [formControl]="keywordCtrl"
                (keyup.enter)="onKeywordSearch()"
                placeholder="Search products by name..."
              />
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="onKeywordSearch()"
              >
                <mat-option
                  *ngFor="let keyword of filteredKeywords | async"
                  [value]="keyword.keywordName"
                >
                  <span>{{ keyword.keywordName }}</span>
                </mat-option>
              </mat-autocomplete>
            </div>
            <div class="col-md-1 search" (click)="onKeywordSearch()">
              <button mat-button>
                <img
                  src="/assets/images/landing-page/MagnifyingGlass.png"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 details" *ngIf="isLoggedIn">
        <div class="request-quote">
          <a (click)="openRequestQuoteDialog(null, null)">Request A Quote</a>
        </div>

        <!-- <img class="msg" src="/assets/images/header/msg icon.png" alt="" />
      <div class="notification">
        <img src="/assets/images/header/notification.png" alt="" />
        <img class="dot" src="/assets/images/header/Ellipse 717.png" alt="" />
      </div> -->
        <img
          class="profile-image"
          [src]="
            companyLogo ? companyLogo : '/assets/images/avatar-placeholder.png'
          "
          alt=""
        />
        <span class="text-capitalize"
          >{{ userData?.firstName }} {{ userData?.lastName }}</span
        >
        <button
          class="open-menu"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          (mouseenter)="trigger.openMenu()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item routerLink="/dashboard/user">
            <mat-icon>account_circle</mat-icon>
            <span>My Profile</span>
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
      <div class="col-md-3 action" *ngIf="!isLoggedIn">
        <a (click)="openRequestQuoteDialog(null, null)">Request A Quote</a>
        <div class="signin_signup">
          <button routerLink="/auth/sign-in" class="">Sign In</button>
          <span>
            New User?
            <a href="" routerLink="/auth/sign-up">Sign Up</a>
          </span>
        </div>
      </div>
      <div class="col-md-1 change-currency">
        <mat-form-field>
          <mat-select
            [formControl]="currencyCtrl"
            [placeholder]="currencyPlaceholder"
            #currencySelect
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="currencyFilterCtrl"
                placeholderLabel="Search Currency..."
                noEntriesFoundLabel="'No currency found!'"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option
              *ngFor="let currency of filteredCurrency | async"
              [value]="currency.currencyCode"
              (click)="getSelectedCurrency(currency)"
            >
              {{ currency.currencyCode }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</header>

<!-- Mobile Header-->

<header id="mobile-header" class="d-none">
  <div class="row header-middle-mobile">
    <div class="col-md-4 category" (click)="toggleSideMenu()">
      <mat-icon>menu</mat-icon>
    </div>

    <div class="col-md-4 logo">
      <img
        class="img-fluid"
        src="/assets/images/bbi-logo.png"
        alt="brand-logo"
        routerLink="/"
      />
    </div>

    <div class="col-md-4 action">
      <!-- <img
        *ngIf="isLoggedIn"
        class="company-logo"
        [src]="
          companyLogo ? companyLogo : '/assets/images/avatar-placeholder.png'
        "
        alt="logo"
        routerLink="/dashboard/user"
      />
      <div class="signin_signup d-flex" *ngIf="!isLoggedIn">
        <a href="" routerLink="/auth/sign-up">Sign Up</a>
        <mat-icon>account_circle</mat-icon>
      </div> -->

      <div class="change-currency">
        <mat-form-field>
          <mat-select
            [formControl]="currencyCtrl"
            [placeholder]="currencyPlaceholder"
            #currencySelect
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="currencyFilterCtrl"
                placeholderLabel="Search Currency..."
                noEntriesFoundLabel="'No currency found!'"
              ></ngx-mat-select-search>
            </mat-option>

            <mat-option
              *ngFor="let currency of filteredCurrency | async"
              [value]="currency.currencyCode"
              (click)="getSelectedCurrency(currency)"
            >
              {{ currency.currencyCode }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Side Menu -->

    <section id="sideMenu" [@openClose]="isSideMenuOpen ? 'open' : 'closed'">
      <div class="close" (click)="isSideMenuOpen = false">
        <mat-icon *ngIf="isSideMenuOpen">highlight_off</mat-icon>
      </div>
      <div class="content">
        <div class="row header">
          <div
            class="col-md-6 header-logout"
            routerLink="/auth/sign-in"
            *ngIf="!isLoggedIn"
          >
            <a>Login To Your Account</a>
            <img src="/assets/images/header/logout.png" alt="" />
          </div>
          <div
            class="col-md-6 header-signup"
            routerLink="/auth/sign-up"
            *ngIf="!isLoggedIn"
          >
            <a>Sign Up</a>
            <img src="/assets/images/header/user.png" alt="" />
          </div>
          <div
            class="col-md-12 home"
            z
            routerLink="/"
            (click)="toggleSideMenu()"
          >
            <img src="/assets/images/header/home-2.png" alt="" />
            <span>Go To Home</span>
          </div>
        </div>
        <div class="row categories-list">
          <div class="Categories">
            <strong class="cat">Top Categories</strong>
            <ul>
              <li
                [id]="category.categoryId"
                *ngFor="let category of categoryList?.slice(0, 4)"
                (click)="viewCategoryProduct(category.categoryId)"
              >
                <div class="item">
                  <span>{{ category?.categoryName }}</span>
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </div>
              </li>
            </ul>
            <a href="/#categories" (click)="toggleSideMenu()"
              >See All Categories</a
            >
          </div>
        </div>
        <div class="row trending-product">
          <strong>Trending Products</strong>
          <ul>
            <li>
              <a
                href="/#most-loved-products-slider"
                (click)="toggleSideMenu()"
                fragment="most-loved-products"
                >Most Loved Products</a
              >
              <a href="/#new-arrivals" (click)="toggleSideMenu()"
                >New Arrivals</a
              >
              <a href="/#best-seller" (click)="toggleSideMenu()"
                >Best Sellers</a
              >
              <a href="/#selected-suppliers" (click)="toggleSideMenu()"
                >Selected Suppliers</a
              >
            </li>
          </ul>
        </div>
        <div class="row platform-feature">
          <strong>Platform’s Features</strong>
          <ul>
            <li>
              <a routerLink="/auth/sign-up"
                >Register Your Product & Start Selling</a
              >
              <a (click)="openRequestQuoteDialog(null, null)"
                >Have Requirements? Get A Quote!</a
              >
            </li>
          </ul>
        </div>
        <div class="row footer" *ngIf="isLoggedIn">
          <div
            class="col-md-6 settings"
            routerLink="/dashboard/user/personal-profile"
          >
            <mat-icon>settings</mat-icon>
            <span>Your Account & Settings</span>
          </div>
          <div class="col-md-6 logout" (click)="logout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </div>
        </div>
      </div>
    </section>
    <div class="col-md-12 searchbar">
      <div class="row search-item">
        <div class="col-md-2 category">
          <!-- <mat-select
                    class="text-capitalize"
                    name="category"
                    (valueChange)="getCategoryId($event)"
                    [(value)]="selectedCategoryId"
                    placeholder="All"
                  >
                    <mat-option
                      class="text-capitalize"
                      *ngFor="let category of categoryList"
                      [value]="category.categoryId"
                    >
                      {{ category.categoryName }}
                    </mat-option>
                  </mat-select> -->
        </div>
        <div class="search-wrapper">
          <div class="col-md-9 input">
            <input
              matInput
              [matAutocomplete]="auto"
              [formControl]="keywordCtrl"
              (keyup.enter)="onKeywordSearch()"
              placeholder="Search products by name..."
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onKeywordSearch()"
            >
              <mat-option
                *ngFor="let keyword of filteredKeywords | async"
                [value]="keyword.keywordName"
              >
                <span>{{ keyword.keywordName }}</span>
              </mat-option>
            </mat-autocomplete>
          </div>
          <div class="col-md-1 search" (click)="onKeywordSearch()">
            <button mat-button>
              <img
                src="/assets/images/landing-page/MagnifyingGlass.png"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row searchbar" [class.sticky]="isSticky" *ngIf="isSticky">
    <div class="col-md-12 search-item">
      <div class="col-md-2 category">
        <!-- <mat-select
                  class="text-capitalize"
                  name="category"
                  (valueChange)="getCategoryId($event)"
                  [(value)]="selectedCategoryId"
                  placeholder="All"
                >
                  <mat-option
                    class="text-capitalize"
                    *ngFor="let category of categoryList"
                    [value]="category.categoryId"
                  >
                    {{ category.categoryName }}
                  </mat-option>
                </mat-select> -->
      </div>
      <div class="search-wrapper">
        <div class="col-md-9 input">
          <input
            matInput
            [matAutocomplete]="auto"
            [formControl]="keywordCtrl"
            (keyup.enter)="onKeywordSearch()"
            placeholder="Search products by name..."
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="onKeywordSearch()"
          >
            <mat-option
              *ngFor="let keyword of filteredKeywords | async"
              [value]="keyword.keywordName"
            >
              <span>{{ keyword.keywordName }}</span>
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="col-md-1 search" (click)="onKeywordSearch()">
          <button mat-button>
            <img src="/assets/images/landing-page/MagnifyingGlass.png" alt="" />
          </button>
        </div>
      </div>
    </div>
  </div>
</header>
