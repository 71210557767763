<div class="row top-bar_actions">
  <div class="title-search col-md-8">
    <h6 class="page-title text-uppercase fw-bold">{{ pageTitle }}</h6>
    <div class="search-bar">
      <mat-form-field appearance="standard" class="d-flex justify-content-end">
        <input
          #input
          matInput
          (keyup)="onSearch($event)"
          placeholder="Search here..."
        />
        <fa-icon [icon]="['fas', 'search']"></fa-icon>
      </mat-form-field>
    </div>
  </div>
  <div class="action col-md-4">
    <div class="bulk-action me-3">
      <mat-slide-toggle
        [checked]="isEnable == true"
        (change)="onBulkStatusChange($event)"
        matTooltip="Bulk Disable"
      ></mat-slide-toggle>
    </div>
    <div class="add-new">
      <button class="button-primary" (click)="onAdd()">
        <fa-icon [icon]="['fas', 'plus']"></fa-icon> Add
      </button>
    </div>
  </div>
</div>
