import { Component, OnDestroy, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from 'src/app/services/auth.service';
import { Homepage } from './homepage';
import { MatDialog } from '@angular/material/dialog';
import { PostRequestComponent } from 'src/app/shared/post-request/post-request.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit, OnDestroy {
  homePage: Homepage;
  searchOptions = [];
  allCategory: any[] = [];
  categories: any[] = [];
  allProducts: any;
  categoryListShow = 17;
  CategoeryCardShow = 4;
  productShow = 10;
  category: any;
  companyList: any;
  isLoading: boolean = false;

  homeAppliancesCategoryId: any;
  vehiclePartCategoryId: any;
  industrialMachineryCategoryId: any;

  profileOverlay: boolean = false;
  private subscriptions: Subscription[] = [];

  banner: OwlOptions = {
    autoplay: true,
    loop: true,
    dots: false,
    navSpeed: 500,
    navText: ['&#8249', '&#8250;'],
    autoHeight: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  iconListing: OwlOptions = {
    autoplay: false,
    loop: true,
    margin: 10,
    dots: false,
    navSpeed: 500,
    navText: ['&#8249', '&#8250;'],
    autoHeight: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 6,
      },
    },
    nav: true,
  };

  products: OwlOptions = {
    autoplay: true,
    loop: true,
    margin: 10,
    dots: false,
    navSpeed: 500,
    navText: ['&#8249', '&#8250;'],
    autoHeight: true,
    autoWidth: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 6,
      },
    },
    nav: true,
  };

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.homePage = new Homepage();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions to avoid memory leaks
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  toggleOverlay() {
    this.profileOverlay = !this.profileOverlay;
  }
  getData() {
    this.getCategory();
    this.getProductDetails();
    this.getCompanyList();
  }

  getCategory() {
    let pageSize = 10000;
    this.subscriptions.push(
      this.apiService
        .get(`/categories/getAllCategoriesWithProducts/${pageSize}`)
        .subscribe({
          next: (res: any) => {
            this.allCategory = res.content;
            this.processCategoryData();
          },
          error: (err: any) => {
            console.error(err.error.message);
          },
          complete: () => {},
        })
    );
  }

  processCategoryData() {
    let homeAppliances = this.allCategory.filter(
      (item) => item?.categoryName == 'Home Appliances'
    );

    this.homeAppliancesCategoryId = homeAppliances[0].categoryId;

    let vehicleParts = this.allCategory.filter(
      (item) => item?.categoryName == 'Vehicle Parts & Accessories'
    );
    this.vehiclePartCategoryId = vehicleParts[0].categoryId;

    let industrialMachineries = this.allCategory.filter(
      (item) => item?.categoryName == 'Industrial Machinery'
    );
    this.industrialMachineryCategoryId = industrialMachineries[0].categoryId;
  }

  getProductDetails() {
    this.isLoading = true;
    let pageSize = 100;
    this.subscriptions.push(
      this.apiService.get(`/productDetails/size/${pageSize}`).subscribe({
        next: (res: any) => {
          let allProducts = res;
          this.allProducts = allProducts.filter(
            (product: any) =>
              product?.activeInactive == true &&
              product?.productImageUrl &&
              /^(https?:\/\/)/.test(product.productImageUrl)
          );
          this.isLoading = false;
        },

        error: (err: any) => {
          console.error(err.error.message);
          this.isLoading = false;
        },
      })
    );
  }

  getCompanyList() {
    this.subscriptions.push(
      this.apiService.get(`/company/list`).subscribe({
        next: (res: any) => {
          let companyList = res.reverse();

          this.companyList = companyList.filter(
            (company: any) =>
              company.companyLogo !== null &&
              company.companyLogo !== '' &&
              company.activeInactive !== false
          );
        },
        error: (err: any) => {
          console.error(err.error.message);
        },
      })
    );
  }

  openRequestQuote(productName: any, sellerCompanyId: any) {
    if (this.authService.isLoggedIn()) {
      this.dialog.open(PostRequestComponent, {
        data: { productName, sellerCompanyId },
      });
    } else {
      this.toastr.warning('Please sign in first to request your quote!');
      this.router.navigateByUrl('auth/sign-in');
    }
  }

  viewCategoryProduct(id: any) {
    this.router.navigateByUrl(`/products/${id}`);
  }

  viewProduct(id: any) {
    this.router.navigateByUrl(`/product/${id}`);
  }

  logout() {
    this.authService.logout();
  }

  //     var validator = require('gstin-validator');
  //     let validGSTNumber = validator.isValidGSTNumber('07CLRPK2639F1ZU');
  //     console.log('validGSTNumber: ', validGSTNumber);
  //     let validateGSTIN = validator.ValidateGSTIN('07CLRPK2639F1ZU');
  //     console.log('validateGSTIN: ', validateGSTIN);
  //     let gstInfo = validator.getGSTINInfo('07CLRPK2639F1ZU');
  //     console.log('gstInfo: ', gstInfo);
}
