export interface RelatedSearches {
  item: string;
}
export interface TrendingSearches {
  keyword: string;
}

export class ProductPage {
  relatedSearches: RelatedSearches[] = [
    { item: 'Solid High Gloss' },
    { item: 'Solid Collection' },
    { item: 'Solid Sf' },
    { item: 'Solid Sparkles' },
    { item: 'Sparkle Collection' },
    { item: 'Soft Matt Collection' },
    { item: 'Wooden Collection' },
    { item: 'Wood High Gloss' },
    { item: 'Wood Grain' },
  ];
  trendingSearches: TrendingSearches[] = [
    { keyword: 'Artificial Grass' },
    { keyword: 'Flute Collection' },
    { keyword: '3D Laminates' },
    { keyword: 'Marble Designs' },
    { keyword: 'Modern Abstract' },
    { keyword: 'Mettalic Textures' },
    { keyword: 'Natural Wood' },
    { keyword: 'PVC Laminates' },
    { keyword: 'Solid High Gloss' },
    { keyword: 'Solid Collection' },
    { keyword: 'Solid Sf' },
    { keyword: 'Solid Sparkles' },
    { keyword: 'Sparkle Collection' },
    { keyword: 'Soft Matt Collection' },
    { keyword: 'Wooden Collection' },
    { keyword: 'Wood High Gloss' },
    { keyword: 'Wood Grain' },
  ];
}
