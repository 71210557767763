<section id="bbi-footer">
  <footer id="footer_main">
    <div class="row footer-top">
      <div class="col-md-6 logo">
        <img
          src="assets/images/bbi-logo.png"
          class="img-fluid brand-logo"
          alt="logo"
        />
        <img
          src="assets/images/aatmanirbhar-bharat-logo.png"
          class="aatmanirbhar-bharat-logo"
          alt="logo"
        />
        <div class="subscribe">
          <h6>Subscribe To Our Newsletter</h6>
          <div class="newsletter">
            <form
              [formGroup]="newsletterFormGroup"
              (ngSubmit)="subscribeNewsletter()"
            >
              <input
                matInput
                type="email"
                formControlName="email"
                placeholder="Enter your email"
                required
              />
              <button mat-button><mat-icon>arrow_forward</mat-icon></button>
            </form>
          </div>
        </div>
        <mat-error *ngIf="f['email'].touched && f['email'].errors">
          <small *ngIf="f['email'].errors['required']">
            Email address is required!
          </small>
          <small *ngIf="f['email'].errors['email']">Invalid Email! </small>
        </mat-error>
      </div>
      <div class="col-md-6 content">
        <div class="row content_text">
          <div class="col-md-5 text">
            <h6>Important Links</h6>
            <ul>
              <li>
                <a class="footer-links" routerLink="/about-us">About Us</a>
              </li>
              <li>
                <a class="footer-links" routerLink="/contact-us">Contact Us</a>
              </li>
              <li><a class="footer-links" routerLink="/faqs">FAQs</a></li>
            </ul>
          </div>

          <div class="col-md-5 text">
            <h6>Resources</h6>
            <ul class="list-inline">
              <li>
                <a class="footer-links" routerLink="/auth/sign-up"
                  >Sign Up for an Account</a
                >
              </li>
              <li>
                <a class="footer-links" routerLink="/auth/sign-in"
                  >Sign In to your Account</a
                >
              </li>
              <li>
                <a
                  class="footer-links"
                  href="https://www.youtube.com/@BulkBuyIndia"
                  target="_blank"
                  >Video Tutorials
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row footer-middle">
      <div class="col-md-4 media">
        <span>Follow us on:</span>
        <img
          src="/assets/images/landing-page/instagram_svg.png"
          alt="insta-icon"
        />
        <img src="/assets/images/landing-page/Facebook svg.png" alt="fb-icon" />
        <img src="/assets/images/landing-page/X svg.png" alt="x-icon" />
        <img
          src="/assets/images/landing-page/YouTube svg.png"
          alt="youtube-icon"
        />
        <img
          src="/assets/images/landing-page/LinkedIn svg.png"
          alt="linkedin-icon"
        />
      </div>
      <div class="col-md-8 site-map">
        <span>Site Map |</span>
        <span>User Agreement |</span>
        <span>Declaration |</span>
        <span>Product Listing Policy |</span>
        <span>Product Index</span>
      </div>
    </div>
    <div class="row footer-bottom">
      <div class="col-md-12 terms">
        <span>Terms of Use</span>
        <div class="copyright">
          <img src="" alt="" />
          <span>2023 | Bulk Buy India | All Rights Reserved</span>
        </div>
        <span>Privacy Policy</span>
      </div>
    </div>
  </footer>

  <!-- Mobile Footer -->

  <footer id="footer_mobile">
    <div class="row footer-top">
      <div class="col-md-6 logo">
        <img
          src="assets/images/bbi-logo.png"
          class="img-fluid brand-logo"
          alt="logo"
          routerLink="/"
        />
        <img
          src="assets/images/aatmanirbhar-bharat-logo.png"
          class="aatmanirbhar-bharat-logo"
          alt="logo"
        />
      </div>
      <div class="subscribe my-3">
        <h6>Subscribe To Our Newsletter</h6>
        <div class="newsletter">
          <form
            [formGroup]="newsletterFormGroup"
            (ngSubmit)="subscribeNewsletter()"
          >
            <input
              matInput
              type="email"
              formControlName="email"
              placeholder="Enter your email"
              required
            />
            <button mat-button><mat-icon>arrow_forward</mat-icon></button>
          </form>
        </div>
      </div>
      <mat-error *ngIf="f['email'].touched && f['email'].errors">
        <small *ngIf="f['email'].errors['required']">
          Email address is required!
        </small>
        <small *ngIf="f['email'].errors['email']">Invalid Email! </small>
      </mat-error>

      <div class="col-md-6 content">
        <div class="row content_text">
          <div class="col-md-3 text">
            <div class="heading" (click)="toggleList('whoAreWe')">
              <h6>Who Are We</h6>
              <span
                ><mat-icon *ngIf="!showWhoAreWeList"
                  >keyboard_arrow_down</mat-icon
                >
                <mat-icon *ngIf="showWhoAreWeList"
                  >keyboard_arrow_up</mat-icon
                ></span
              >
            </div>

            <ul
              [ngClass]="{
                'list-transition': true,
                'list-visible': showWhoAreWeList
              }"
            >
              <li><a routerLink="#">About Us</a></li>
              <li><a routerLink="#">What We Do</a></li>
              <li><a routerLink="#">Mission & Vision</a></li>
            </ul>
          </div>
          <div class="col-md-3 text">
            <div class="heading" (click)="toggleList('links')">
              <h6>Links</h6>
              <span
                ><mat-icon *ngIf="!showLinksList">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="showLinksList"
                  >keyboard_arrow_up</mat-icon
                ></span
              >
            </div>
            <ul
              class="list-transition"
              [ngClass]="{
                'list-visible': showLinksList
              }"
            >
              <li><a routerLink="#">Top Categories</a></li>
              <li><a routerLink="#">Request A Quote</a></li>
              <li><a routerLink="#">Contact Us</a></li>
            </ul>
          </div>
          <div class="col-md-3 text">
            <div class="heading" (click)="toggleList('resources')">
              <h6>Resources</h6>
              <span
                ><mat-icon *ngIf="!showResourcesList"
                  >keyboard_arrow_down</mat-icon
                >
                <mat-icon *ngIf="showResourcesList"
                  >keyboard_arrow_up</mat-icon
                ></span
              >
            </div>
            <ul
              class="list-inline list-transition"
              [ngClass]="{
                'list-visible': showResourcesList
              }"
            >
              <li><a routerLink="/auth/sign-up">Signup for an Account</a></li>
              <li><a routerLink="#">Tutorials </a></li>
              <li><a routerLink="#">FAQs</a></li>
            </ul>
          </div>
          <div class="col-md-3 text">
            <div class="heading">
              <h6>Terms of Use</h6>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
          <div class="col-md-3 text">
            <div class="heading">
              <h6>Privacy Policy</h6>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row footer-middle">
      <div class="col-md-4">
        <p>Follow us on:</p>
        <div class="social-media-icon">
          <img
            src="/assets/images/landing-page/instagram_svg.png"
            alt="insta-icon"
          />
          <img
            src="/assets/images/landing-page/Facebook svg.png"
            alt="fb-icon"
          />
          <img src="/assets/images/landing-page/X svg.png" alt="x-icon" />
          <img
            src="/assets/images/landing-page/YouTube svg.png"
            alt="youtube-icon"
          />
          <img
            src="/assets/images/landing-page/LinkedIn svg.png"
            alt="linkedin-icon"
          />
        </div>
      </div>
      <div class="col-md-8 links">
        <span>Site Map |</span>
        <span>User Agreement |</span>
        <span>Declaration |</span>
        <span>Product Listing Policy |</span>
        <span>Product Index</span>
      </div>
    </div>
    <div class="row footer-bottom">
      <div class="copyright text-center py-2">
        <span>@ 2023 | Bulk Buy India | All Rights Reserved</span>
      </div>
    </div>
  </footer>

  <div class="whatsapp-float" (click)="openWhatsApp()">
    <img src="/assets/images/footer/WhatsApp svg.png" alt="icon" />
  </div>
  <div class="scroll-top" (click)="scrollToTop()">
    <img src="/assets/images/footer/bottomtotop.png" alt="icon" />
  </div>
</section>
